import { Injectable } from '@angular/core';

import { NEVER, Observable, Subject, merge } from 'rxjs';

import { catchError, filter, map, mergeMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { FirebaseAuthService } from './fbauth.service';
import { firestoreRefValuesChanges } from 'src/app/utils';
import { doc, getFirestore } from 'firebase/firestore';
const LAST_NOTIFICATION_INDEX = 'LETZA_NIOAS';
const LAST_NOTIFICATION_INDEX_MESSAGE_BUS = 'LETZA_NIOAS_MESSAGE_BUS';


@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    /* I am creating this proxy observable so to limit the firebase subscription to just one. Moreover, it is required so index count is not passed for successive subscriptions */
    private apiUpdatedSource = new Subject<any>();
    public apiUpdated$: Observable<any> = this.apiUpdatedSource.asObservable();

    private messageBusSource = new Subject<any>();
    public messageBusSource$: Observable<any> = this.messageBusSource.asObservable();



    private subscribed_success = false;

    constructor(
        private authService: FirebaseAuthService
    ) {


    }


    public subscribe() {
        if (this.subscribed_success) return false;
        /* the plan is to use as a general notification system.  so far in theses ways
            1) the api will update firestore with latest changes and here will be the listener to all such updates. This will not push any data to clients only make the client aware that the things have changed
                We let the clients decides how to proceed.
    
            2) for live documents. before a document is saved to to the api it will live in realtime db. this way we can have live collaborations on documents. 
        */
        let firestore$ = firestoreRefValuesChanges(doc(getFirestore(), 'pub-sub/bdwx-update'))
            .pipe(
                catchError(e => {
                    console.log(e);
                    return NEVER;
                }),
                // tap(v => console.log(v)),
                filter(this.filterForNewest(LAST_NOTIFICATION_INDEX, 'index_count')),
                tap(v => this.apiUpdatedSource.next(v))
            );
        let messageBus$ = firestoreRefValuesChanges(doc(getFirestore(), 'pub-sub/message-bus'))
            .pipe(
                catchError(e => {
                    return NEVER;
                }),
                tap(v => console.log(v)),
                filter(this.filterForNewest(LAST_NOTIFICATION_INDEX_MESSAGE_BUS, 'index_count')),
                map((doc: any) => this.messageBusSource.next(doc.message))
            );
        const successAuth$ = this.authService.hasAccess(environment.app_access, { checkMode: 'any' })
        return successAuth$.pipe(mergeMap((user: any) => user ? merge(firestore$, messageBus$) : NEVER)).subscribe();
    }


    private filterForNewest = (cache_key, index_key) => {
        return (val) => {
            const index_count = val[index_key];
            const last_index_count = localStorage.getItem(cache_key);
            localStorage.setItem(cache_key, index_count);
            return !last_index_count || index_count > last_index_count;

        }
    }


}
