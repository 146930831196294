import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
// import { saveAs } from 'file-saver';
import { getBlob, getStorage, ref, uploadBytes } from 'firebase/storage';
import { isEqual as _isEqual } from 'lodash-es';
import { Observable, from, of } from 'rxjs';
import { concatMap, map, tap } from 'rxjs/operators';
import { saveBlobAsFile } from 'src/app/utils';
import { environment } from 'src/environments/environment';
import { GlobalService } from './global.service';


export const FOLDER_FILE_PLACE_HOLDE ='DO_NOT_DELETE_folder-structure-placeholder';


@Injectable({ providedIn: 'root' })
export class FilesService {
  private serverURL: string = environment.serverURL;

  constructor(private httpClient: HttpClient, private globalDataService: GlobalService) { }

  public getDownLoadPdf(id: number): Observable<Blob> {
    return this.httpClient.post<any>(`${this.serverURL}/api/study_service/doc`, {
      model_id: id,
      model_type: 'study_service',
      tmp_url: true,
    }).pipe(map(d => d.data[0].tmp_url));
  }

  public storeFireStorageFile(path, blob): Observable<any> {

    return from(uploadBytes(ref(getStorage(), path), blob));

  }


  private fireStorageFiles: Record<string, any> = {};




  private getJsonVersion(path: string, data: any) {
    const version = this.fireStorageFiles[path].versions || [];
    version.unshift(data);
    console.log(version);
    return version.slice(0, 10);// picked 10 out of a hate. we keep 30 version; When I am bored i will paly with diffs 
  }

  public updateFireStorageJsonFile(path: string, data: any): Observable<{ current: any, versions: any[] }> {

    if (_isEqual(data, this.fireStorageFiles[path].current)) return of(data);
    data = data.current || { current: data, versions: this.getJsonVersion(path, data) };

    const blob = new Blob([JSON.stringify(data)], { type: "application/json" });

    return from(
      uploadBytes(ref(getStorage(), path), blob)
        .then(() => this.getFireStorageJsonFile(path))
    );
  }


  public async getFireStorageJsonFile(path: string, fetch = true): Promise<{ current: any, versions: any[] }> {
    if (!fetch) {
      return { current: this.fireStorageFiles[path]?.current, versions: this.fireStorageFiles[path]?.versions };
    }
    try {
      const blob = await getBlob(ref(getStorage(), path));
      const json_string = await blob.text();
      const josn = JSON.parse(json_string);
      this.fireStorageFiles[path] = josn;
      return {
        current: josn.current, versions: this.fireStorageFiles[path].versions
      }

    } catch (e) {
      console.error('unable to load firabase storage file:' + path);
      return null;
    }
  }


  /**
   * 
   * @param downloadRows$ 
   * @param totalRows$ 
   * @param fileName 
   * @param fileType 
   * @param limit 
   * @param extraText this is just a any string to append at end of table data. Note that for json table it may tricky to add a string and keep it a valid json file. 
   */
  public downloadTableRows(downloadRows$, totalRows$, fileName: string = '', fileType: 'csv' | 'json' = 'csv', limit: number = 0, headerMap = undefined, extraText: string = ''): Observable<any> {
    const jsonToCsv = (rows: any[]): string => {
      let csv: string = Object.keys(rows[0]).map(headerCell => headerMap && headerMap[headerCell] ? headerMap[headerCell] : headerCell).join(',');
      return rows.reduce((p, c) => {
        p = p + ' ' + Object.values(c).join(',');
        return p;
      }, csv);
    };

    const total_records$ = limit > 0 ? of(limit) : totalRows$;
    return (total_records$).pipe(
      concatMap(totalRecords => {
        const max = this.globalDataService.tsw_config.misc.download_file_max_rows;
        if (!max || totalRecords > max) {
          this.globalDataService.addError({ type: 'invalid_value', data: 'max rows exceeded' });
          return of(null);
        };
        return downloadRows$(totalRecords).pipe(tap((rows: any) => {
          const text = fileType == 'csv' ? jsonToCsv(rows) : JSON.stringify(rows);
          const fileName_ = (fileName || new Date().getTime().toString()) + '.' + fileType;
          const type_ = fileType == 'csv' ? "text/plain;charset=utf-8" : "application/json";
          saveBlobAsFile(new Blob([text + extraText], { type: type_ }), fileName_);
        }));
      }));
  }

}
