import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

@Component({
    selector: 'app-summary-modal',
    templateUrl: './summary-modal.component.html',
    styleUrls: ['./summary-modal.component.scss'],
    standalone:true,
    imports:[MatDialogModule, MatIconModule, CommonModule]
})
export class SummaryModalComponent implements OnInit {
    public summaryItems;
    public listItemsMap;
    public sub_total;

    constructor(public dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) public data: any,) { }

    ngOnInit() {
        this.sub_total = this.data.subtotal;
        this.listItemsMap = this.data.map;
        this.summaryItems = this.data.items;
    }

    public closeModal() {
        this.dialogRef.close();
    }
}