import { HttpClient } from "@angular/common/http";
import { Injectable, KeyValueDiffers } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment.prod";



export interface ErrorData {
  type: "http" | "invalid_value" | "misc";
  data?: any;
}



@Injectable({
  providedIn: "root"
})
export class GlobalService {




/**
 * @todo make this private , move creating proxy here and not in resolver, perhaps make it read only we can do it wit proxy to ignore all sets but it will have to be done recursively. 
 */
  public tsw_config;
 

  constructor(private matIconRegistry: MatIconRegistry,private differs: KeyValueDiffers,
    private domSanitizer: DomSanitizer, private httpClient: HttpClient, private win: Window) {



     

     
    this.onInitDoStuff();
    

  }


  private globalErrorsSubject = new Subject<ErrorData>();

  public globalErrors$: Observable<ErrorData> = this.globalErrorsSubject.asObservable().pipe(
    map((errorData: any, index: number) => {

      return errorData;
    })
  );

  public addError(errorData: ErrorData) {
    const handleHttp = (err: any) => {
      return err;
    }
    if (errorData.type === 'http') errorData = handleHttp(errorData);

    this.globalErrorsSubject.next(errorData);
  }

  /**
   * This is used to handle success message of action  performed.
   */
  public notification$ = new Subject<any>();


  public serverURL = environment.serverURL;

  private httpLoading: Subject<any> = new Subject()
  public httpLoading$ = this.httpLoading.asObservable();

  private httpRequestsId: number = 0;
  private httpRequestsMap = {};
  private activeHttpRequests = {};
  public httpRequestsLedger = {
    add: () => {
      this.httpRequestsMap[++this.httpRequestsId] = { start: new Date().getTime() };
      this.activeHttpRequests[this.httpRequestsId] = true;
      this.httpLoading.next(this.activeHttpRequests);

      return this.httpRequestsId;
    },
    complete: (id: number) => {
      this.httpRequestsMap[id].end = new Date().getTime();
      delete (this.activeHttpRequests[id]);
      this.httpLoading.next(this.activeHttpRequests);
    }

  }

  private onInitDoStuff() {
    this.matIconRegistry.addSvgIcon(
      "settings-button",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/img/svg/settings-button.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "info",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/img/svg/info.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "sort-type-none",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/img/svg/sort-type-none.svg"));
    this.matIconRegistry.addSvgIcon(
      "sort-type-asc",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/img/svg/sort-type-asc.svg"));
    this.matIconRegistry.addSvgIcon(
      "sort-type-desc",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/img/svg/sort-type-desc.svg"));
    this.matIconRegistry.addSvgIcon(
      "filter-change-position",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/img/svg/filter-change-position.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "close-button",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/img/svg/close-button.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "filter",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/img/svg/filter.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "open-folder",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/img/svg/open-folder.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "pdf-file",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/img/svg/pdf-file.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "vertical-filter",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/img/svg/vertical-filter.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "trash-outline",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/img/svg/trash-outline.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "file-tray-full-outline",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/img/svg/file-tray-full-outline.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "add-circle-outline",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/img/svg/add-circle-outline.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "close-circle-outline",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/img/svg/close-circle-outline.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "chevron-down-outline",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/img/svg/chevron-down-outline.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "copy-outline",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/img/svg/copy-outline.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "list-outline",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/img/svg/list-outline.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "cloud-download-outline",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/img/svg/cloud-download-outline.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "funnel-outline",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/img/svg/funnel-outline.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "toggle-outline",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/img/svg/toggle-outline.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "close-outline",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/img/svg/close-outline.svg")
    );
  }
}
