import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  cloneDeep as _cloneDeep,
  get as _get, merge as _merge
} from 'lodash-es';
import moment  from "moment";import { BehaviorSubject, EMPTY, Observable, of, Subscription, throwError } from 'rxjs';
import { concatMap, delay, expand, last, map } from 'rxjs/operators';
import {
  cleanFilters, ColumnDefs, Filter,
  SortType, TableResponse
} from 'src/app/modules/shared/models';
import { environment } from 'src/environments/environment';
import { FilesService } from './files.service';
import { GlobalService } from './global.service';


@Injectable()
export class TableDataService {

  constructor(private httpClient: HttpClient, private globalService: GlobalService, public filesService: FilesService) {
    this.shouldUseFastScroller = this.shouldUseFastScrollBehavior.getValue();
    this.shouldUseFastScrollBehaviorSubscription = this.shouldUseFastScrollBehavior.subscribe(item => {
      this.shouldUseFastScroller = item;
    });
  }


  /**
   * Store the default filter to add to each request. We will just add it to the table request before we call from server.
   */
  private filterOnlyData = [];

  private filtersBehavior: BehaviorSubject<Filter[]> = new BehaviorSubject([]);

  public tablesList: BehaviorSubject<any[]> = new BehaviorSubject([]);

  public tableRequest: any = null;





  private shouldUseFastScroller: boolean;

  public tableConfigChanged: BehaviorSubject<any> = new BehaviorSubject(null);

  private shouldUseFastScrollBehaviorSubscription: Subscription;

  private serverURL: string;

  public shouldUseFastScrollBehavior = new BehaviorSubject<boolean>(false);

  public tableColumns: any[];

  public columnDefs: ColumnDefs[];


  private loadedRows: any[] = [];
  private total_record_cached = 0;
  private next_scroller_id_cached = '';
  private scroller_cached_size: number;




  private getTableDef(tablePath) {
    return Array.isArray(tablePath) ?  _get(this.globalService.tsw_config.tables, tablePath) : tablePath ; // if not array we try the actual object passed
  }

  public downloadFileRows(filters: Filter[],  fileName: string = '', fileType: 'csv' | 'json' = 'csv', limit: number = 0): Observable<any> {
    return this.filesService.downloadTableRows(rowNum => this.getRows(0, rowNum, filters).pipe(map(tResp => tResp.data)), this.getRows(0, 1, filters).pipe(map(tResp => tResp.total_record)), fileName, fileType, limit);
  }

  public initTableData(tablePath: string[] | object ): { defaultFilters: Filter[], columnDefs: ColumnDefs[] } {
    this.tableRequest = this.getTableDef(tablePath);
    const columnDefs: ColumnDefs[] = Object.keys(this.tableRequest.table_def.fields).map(v => {
      const colDef = this.tableRequest.table_def.fields[v];
      return {
        columnName: v,
        displayed_name: colDef.display_name || v.split('.').pop().replace(/_/g, ' '),
        dataType: v.toLowerCase().endsWith('date') ? 'date' : 'string',
      };
    });
    this.serverURL = `${environment.serverURL}/api/${this.tableRequest.apiURl}`;
    return { defaultFilters: this.getDefaultFilters(this.tableRequest.table_def), columnDefs };
  }

  public getOnFiltersChange$() {
    return this.filtersBehavior.asObservable().pipe(
      map(f => _cloneDeep(f)),
      map(ff => cleanFilters(ff)),
    );
  }




  public changeFilterDataState(filters: Filter[]) {
    this.tableConfigChanged.next(filters);
  }

  public changeShouldUseFastScroll(result: boolean) {
    this.shouldUseFastScrollBehavior.next(result);
  }

  private tablePageRequest(page: number, size: number, filters: Filter[], useFastScroller: boolean): Observable<TableResponse> {
    this.loadedRows = []; // always clear the loaded rows. Either for a switch to pagination or when for initializing scroller;
    this.scroller_cached_size = size;
    const url = useFastScroller ? `${this.serverURL}/table_scroller?size=${size}`
      : `${this.serverURL}/find?page=${page + 1}&size=${size}`;

    return this.httpClient.post<TableResponse>(url, this.processFilterToRequestModel(filters));
  }

  private nextScrollerRequest(scrollerID: string, size: number): Observable<TableResponse> {
    // I am worried how  fast scroller handles inconsistent sizes in a single scroller I wil think about it
    size = this.scroller_cached_size;
    return this.httpClient.get<TableResponse>(`${this.serverURL}/table_scroller/${scrollerID}?size=${size}`).pipe(
      map(response => {

        this.total_record_cached = response.total_record;
        this.next_scroller_id_cached = response.next_scroller_id;
        this.loadedRows.push(...response.data);
        return response;
      })
    );
  }

  private createNewScroller(pageSize: number, filters: Filter[]): Observable<TableResponse> {
    return this.tablePageRequest(-1, pageSize, filters, true).pipe(
      concatMap(response => this.nextScrollerRequest(response.next_scroller_id, pageSize))
    );
  }

  private handelClassicalPaginationRequest(pageNumber: number, pageSize: number, filters: Filter[]): Observable<TableResponse> {
    return this.tablePageRequest(pageNumber, pageSize, filters, false);
  }




  public getRows(pageNumber: number, pageSize: number, filters: Filter[], extraOptions= undefined): Observable<TableResponse> {
    if (extraOptions !== undefined) { _merge(this.tableRequest.extra_request_options , extraOptions); }
    if (!this.shouldUseFastScroller) { return this.handelClassicalPaginationRequest(pageNumber, pageSize, filters); }

    if (pageNumber == 0) { return this.createNewScroller(pageSize, filters); } // clear the cache

    if (!(this.loadedRows.length > 0 && this.next_scroller_id_cached && this.total_record_cached)) {
      return throwError('invalid scroller state');
    }

    return this.getScrollerRows(pageNumber, pageSize).pipe(
      map(() => this.getCachedScrollerRows(pageNumber, pageSize))
    );
  }


  private getCachedScrollerRows(pageNumber: number, pageSize: number) {
    const start = pageNumber * pageSize;
    const end = pageNumber * pageSize + pageSize;
    return {
      data: this.loadedRows.slice(start, end),
      total_record: this.total_record_cached,
      error: false,
      next_scroller_id: this.next_scroller_id_cached
    };
  }



  private getScrollerRows(pageNumber: number, pageSize: number): Observable<TableResponse | any> {
    let emergency_break = 0;
    if (this.loadedRows.length >= (pageNumber * pageSize + pageSize)) {

      return of('').pipe(delay(100));
    }
    return this.nextScrollerRequest(this.next_scroller_id_cached, pageSize).pipe(
      expand(response => {
        emergency_break++;
        if (emergency_break > 50) { throwError('too many scroller requests'); }
        if (this.loadedRows.length >= (pageNumber * pageSize + pageSize) || response.data.length < 1 || this.loadedRows.length >= this.total_record_cached) { return EMPTY; }
        return this.nextScrollerRequest(response.next_scroller_id, pageSize);
      }),
      last()
    );
  }

  /**
   * Iterates over the default filters and converts special keywords like yesterday to actual values
   * @param tableView
   */
  private handelSpecialFilterKeywords(keyword: string): any {
    if (keyword == '_#yesterday#_') { return moment().subtract('days', 1).toDate(); }
    if (keyword == '_#today#_') { return moment().toDate(); }
    return keyword;
  }


  private getStaticFilterOnlyData() {
    return  this.filterOnlyData = this.tableRequest.table_def.default_filter.filter(v => v.filter_only);
  }
  private addDefaultStaticFilters(filters: Filter[]) {
    const staticFilters = this.tableRequest.table_def.default_filter.filter(v => !v.filter_only);
    return filters.map( f => {
      const sFilter =  staticFilters.find(sf => sf.fqdn == f.name);
      if (!sFilter) { return undefined; }
      if (!f.selectedOperator  || sFilter.query_operator == f.selectedOperator) { // if the column is filtered or the column has anouf
        f.value = sFilter.filter_value;
        f.selectedOperator = sFilter.query_operator;
        return sFilter;
      }
      return undefined;

    }).filter(item => item !== undefined);

  }

  private getDefaultFilters(tableView): Filter[] {
    const result = new Array<Filter>();
    const fields = tableView.fields;
    const fieldsNames = Object.keys(fields);
    const filtersUniqCols = tableView.uniqColsVals;
    tableView.default_filter = tableView.default_filter ? tableView.default_filter.map(v => {
      v.filter_value = this.handelSpecialFilterKeywords(v.filter_value);
      return v;
    }) : [];


    fieldsNames.forEach((fieldName: any, index: number) => {
      // const givenFilters = tableView.default_filter.filter(v => v.fqdn == fieldName && !v.filter_only);
      // const givenFilter = givenFilters.length > 0 ? givenFilters[0] : false;
      const filterValue = fields[fieldName];
      const newFilter: Filter = {
        name: fieldName,
        operators: filterValue.legalQueryOperators,
        sortType: SortType.none,
        validValues: [],
        // selectedOperator: givenFilter ? givenFilter.query_operator : null,
        selectedOperator:  null,
        // value: (givenFilter && !_isArray(givenFilter.filter_value)) ? givenFilter.filter_value : null,
        value:  null,
        // selectedValues: (givenFilter && _isArray(givenFilter.filter_value)) ? givenFilter.filter_value : [],
        selectedValues : [],
        selectedDate: { start: null, end: null },
        columnNumber: 0,
        // defaultFilter: givenFilter.length > 0 ? givenFilter[0] : undefined,
        defaultFilter:   undefined,
      };

      if (filtersUniqCols[fieldName]) {
        filtersUniqCols[fieldName].forEach((uniqCol: string) => {
          newFilter.validValues.push(uniqCol);
        });
      }
      result.push(newFilter);

    });

    return result;
  }



  private processFilterToRequestModel(filters: Filter[]) {
    return [];
  }
}
