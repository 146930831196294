import { CommonModule } from '@angular/common';
import { OnInit, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
    selector: 'app-icon-button',
    templateUrl: './icon-button.component.html',
    styleUrls: ['./icon-button.component.scss'],
    host: {
        "[style.pointer-events]": "disabled ? 'none' : 'auto'"
    },
    standalone: true,
    imports: [CommonModule,MatTooltipModule]
})
export class IconButtonComponent implements OnInit, OnChanges {
    // The label to be displayed under the icon, if needed
    @Input() label: string;

    // A specific class for an icon-button
    @Input() buttonClass: string | object = 'ibutton-simple';

    // Determines whether the button is disabled
    @Input() disabled: boolean = false;

    // The CSS class of the icon
    @Input() icon: string = '';

    // The tooltip
    @Input() tooltip: string;

    // The tooltip
    @Input() hasDropdown: boolean = false;

    public showDropdown: boolean;
    public elClass: string;

    constructor() {
        this.showDropdown = false;
    }

    ngOnInit() {
        if (typeof (this.buttonClass) === 'string') {
            this.elClass = this.buttonClass;
        }

        if (typeof (this.buttonClass) === 'object') {
            const keys = Object.keys(this.buttonClass);
            keys.forEach(key => {
                if (this.buttonClass[key]) {
                    this.elClass += String(key);
                    this.elClass += " ";
                }
            });
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        this.detectButtonClassChanges(changes);
    }

    public detectButtonClassChanges(changes: SimpleChanges | any) {
        if (changes.buttonClass) {
            this.elClass = "";
            const classInput = changes.buttonClass;
            if (typeof (classInput.currentValue) === 'string') {
                this.elClass = classInput.currentValue;
            }

            if (typeof (classInput.currentValue) === 'object') {
                const keys = Object.keys(classInput.currentValue);
                keys.forEach(key => {
                    if (classInput.currentValue[key]) {
                        this.elClass += String(key);
                        this.elClass += " ";
                    }
                });
            }
        }
    }

    public dropdownTriggerClick(event) {
        event.stopPropagation();
        this.showDropdown = !this.showDropdown;
    }
}