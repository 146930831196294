import { Directive, HostListener, Output, EventEmitter, ElementRef } from '@angular/core';

@Directive({
    selector: '[mousewheel]',
    standalone: true
})
export class MousewheelDirective {
    @Output() mousewheel: EventEmitter<any> = new EventEmitter<any>();

    constructor(private elemRef: ElementRef) { }

    @HostListener('document:mousewheel', ['$event'])
    scrolling($event: any) { }
}