import { Directive, HostListener, Output, EventEmitter, ElementRef,Input, Renderer2  } from '@angular/core';

@Directive({
    selector: '[displayEllipse]',
    standalone:true
})
export class displayEllipseDirective {
    private nativeElem;
    private data:string;

    @Input()
    set displayEllipse(data){
        this.data = data;
    }
    constructor(private elemRef: ElementRef, private renderer: Renderer2 ) {
        this.nativeElem = elemRef.nativeElement;
    }

    private isOverflow = (nativeElement) => nativeElement.offsetWidth < nativeElement.scrollWidth;


    @HostListener('mouseover', ['$event'])
    onHover(event: any) {
    
        const titleData = this.isOverflow(this.nativeElem) ? this.data : ''; 
        this.renderer.setAttribute(this.nativeElem, 'title',titleData);

    }
}