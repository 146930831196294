<mat-list>
    <div mat-subheader>Folders</div>
    @for (ref of availableFolders; track ref.fullPath) {
      <mat-list-item style="cursor: pointer;" (dblclick)="openFolder(ref)">
        <mat-icon matListItemIcon>folder</mat-icon>
        <div matListItemTitle>{{ref.name}}</div>
      </mat-list-item>
    }
    <mat-divider></mat-divider>
    <div mat-subheader>Files</div>
    @for (file of availableFiles; track file.fullPath) {
      <mat-list-item style="cursor: pointer;" (dblclick)="openFile(file)">
        <mat-icon matListItemIcon>note</mat-icon>
        <div matListItemTitle>{{file.name}}</div>
      </mat-list-item>
    }
  </mat-list>