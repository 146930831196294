import { environment as defaultEnvironment } from './environment.default';

export const environment = {
  apple: 'pie',
  ...defaultEnvironment,
  production: false,

  serverURL: 'https://brightviewradiology.com/',

  firebaseConfig: {

    apiKey: "AIzaSyA2qUJpM5ktzyjLPDAC-Okvm8wSFHoO2qE",
    authDomain: "bvr-app-9dc9b.firebaseapp.com",
    databaseURL: "https://bvr-app-9dc9b.firebaseio.com", //i am not sure this is for firestore 
    projectId: "bvr-app-9dc9b",
    storageBucket: "bvr-app-9dc9b.appspot.com",
    messagingSenderId: "215977749396",
    appId: "1:215977749396:web:e3aeda48b1189914fbbd81",
    measurementId: "G-CWWYCXEHQE"

  },
  radiologyReportsStoragePath: "radiology-reports-prod",
}
