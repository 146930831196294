import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ListResult, StorageReference, getStorage, listAll, ref } from "firebase/storage";
import { displaySbackBarError } from "src/app/utils";
import { FOLDER_FILE_PLACE_HOLDE } from "../../../modules/shared/services";

@Component({
    selector: 'editor-file-explorer',
    templateUrl: 'file-explorer.component.html',
    styleUrls: ['file-explorer.component.scss'],
    standalone: true,
    imports: [CommonModule, MatListModule, MatIconModule]
})
export class FileExplorerComponent implements OnInit, OnChanges {
    @Input() root_path: string = '/';
    @Output() selectedRef: EventEmitter<StorageReference> = new EventEmitter();
    @Output() selectedFolder: EventEmitter<string> = new EventEmitter();


    public availableFolders: StorageReference[] = [];
    public availableFiles: StorageReference[] = [];
    public active_storage_reference: StorageReference;
    constructor() { }

    ngOnChanges(changes: SimpleChanges): void {

    }
    ngOnInit() {
        this.explorePath();
    }

    public async explorePath() {

        let listResult: ListResult
        try {
            listResult = await listAll(this.active_storage_reference ?? ref(getStorage(), this.root_path))
        } catch (error) {
            displaySbackBarError(error);
        }

        this.availableFolders = listResult.prefixes;
        this.availableFiles = listResult.items.filter(f => f.name !== FOLDER_FILE_PLACE_HOLDE);
        this.selectedFolder.emit(this.active_storage_reference?.fullPath)

    }

    public goBack() {
        this.active_storage_reference = this.active_storage_reference.parent;
        this.explorePath();

    }

    public reload(): void {
        this.explorePath();
    }



    public openFolder(folder: StorageReference) {

        this.active_storage_reference = folder;
        this.explorePath();

    }

    openFile(ref: StorageReference) {
        this.selectedRef.emit(ref)

    }




}