<div class="search-with-options" (clickOutside)="hideDropdown()">
    <input class="search-with-options__input" [type]="searchOption.filter_type == 'between_date' ? 'date' : 'text'"
        [formControl]="searchInput">
    <mat-chip-set class="active-filter"><mat-chip>{{searchOption.label}}</mat-chip>
    </mat-chip-set>

    <span *ngIf="config.hasOptions" class="dropdown-icon" (click)="toggleDropdown()"><mat-icon
            svgIcon="vertical-filter"></mat-icon></span>
    <div [hidden]="!(config.hasOptions && isDropdownVisible)" class="options-container">
        <h5>Search by:</h5>
        <mat-radio-group>
            <mat-radio-button *ngFor="let opt of config.options" value="{{opt.key}}" [checked]="opt.default"
                (change)="changeDropdownOption(opt)">{{opt.label}}</mat-radio-button>
        </mat-radio-group>
    </div>
</div>