import { Inject, Injectable } from "@angular/core";
import { Sort } from '@angular/material/sort';
import { intersectionBy } from 'lodash-es';
import { NEVER, Observable, Subject, Subscription, merge } from 'rxjs';
import { map, mergeMap, startWith, tap } from 'rxjs/operators';
import {
    Filter,
    addFilterCondition
} from 'src/app/modules/shared/models';
import { dateRangeToDateTimeRange, unsubscribeMap } from 'src/app/utils';
import { DollarStoreService } from './dollarStore.service';
import { FilesService } from './files.service';
import { TableDataService } from './table-data.service';
import { PageEvent } from "@angular/material/paginator";


export interface tableDataEvent {
    rows: any[];
    total_rows: number;
    total_pages: number;
    current_page: number;
}

export interface SearchOption {
    key: string;
    label?: string;
    default?: boolean;
    filter_type?: 'like' | 'between_date';
    val: any;
}

@Injectable()
export class TableService {
    private subs: { [key: string]: Subscription } = {};
    private filesService:FilesService;

    constructor(public tableDataService: TableDataService, private storeService: DollarStoreService, @Inject('tableServiceSource')  staticData?:any) {
        if(staticData != undefined){
            this.tableDataService = staticData;
        }
    }

    public downloadFileRows(  fileName: string = '', fileType: 'csv' | 'json' = 'csv', limit: number = 0, filters: Filter[] = undefined): Observable<any> {
        if(filters == undefined )filters = this.filters;
        return this.tableDataService.filesService.downloadTableRows(rowNum => this.tableDataService.getRows(0, rowNum, filters).pipe(map(tResp => tResp.data)),this.tableDataService.getRows(0, 1, filters).pipe(map(tResp => tResp.total_record)),fileName,fileType,limit);
    }

    public applySimpleFilters(searchOptions: SearchOption[]) {
        this.registerFilters(); // to remove previous conditions

        for (let opt of searchOptions) {

            const filter = this.filters.find(f => f.name == opt.key);
            if (opt.filter_type == 'between_date') {
                const range = dateRangeToDateTimeRange(opt.val, opt.val)
                if (range.start || range.end) addFilterCondition(filter, 'between_date', dateRangeToDateTimeRange(opt.val, opt.val));
            }
            else addFilterCondition(filter, 'like', opt.val);
        }
        this.getFirstPageData();
    }

    public getAdvancedFilterConfig(columnDefs: any) {
        return {
            options: this.filters.map((filter, index) => {
                return {
                    key: filter.name,
                    label: columnDefs[index].label,
                    // label: filter.name,
                    operators: filter.operators,
                    type: filter.operators?.indexOf('between_date') > -1 ? 'date' : 'string',
                    selectedDate: {
                        start: filter.selectedDate.start,
                        end: filter.selectedDate.end
                    },
                    selectedOperator: filter.selectedOperator,
                    value: filter.value
                }
            })
        }
    }

    public applyAdvancedFilters(filterData: Array<Filter>) {
        filterData.forEach((filter, index) => {
            this.filters[index].value = filter.value;
            this.filters[index].selectedDate.start = filter.selectedDate.start;
            this.filters[index].selectedDate.end = filter.selectedDate.end;
            this.filters[index].selectedOperator = filter.selectedOperator;
        });

        this.filters.forEach(filter => {
            if (filter.operators.indexOf('between_date') > -1) {
                if (filter.selectedDate.start || filter.selectedDate.end) {
                    addFilterCondition(filter, 'between_date', dateRangeToDateTimeRange(filter.selectedDate.start, filter.selectedDate.end));
                }
            } else {
                if (filter.value) {
                    addFilterCondition(filter, filter.selectedOperator, filter.value);
                }
            }
        });

        this.getFirstPageData();
    }

    public clearFilters(): void {
        this.filters.forEach((filter, index) => {
            filter.value = null;
            filter.selectedDate.start = null;
            filter.selectedDate.end = null;
            filter.selectedOperator = null;
        });

        this.getFirstPageData();
    }

    public getFirstPageData() {
        this._page_number = 0;
        this.subs['rows'] = this.getRows().subscribe(v => true);
    }

    private dataRowsSource = new Subject<tableDataEvent>();
    private dataSelectionSource = new Subject<{ [key: string]: { row: any, is_displayed: boolean } }>();



    public filters: Filter[];
    private store_uid;
    /**
     * to keep the position of the rows
     */
    private _row_index =[];

    private _paginator$: Observable<PageEvent>;
    private _sorter$: Observable<Sort>;

    private _page_number = 1
    private _page_size: number;
    private _total_record: number;

    private selection_map: { [key: string]: { row: any, is_displayed: boolean } } = {};
    private keyId;
    private currentPageSelection = [];


    public dataRows$ = this.dataRowsSource.asObservable();
    public dataSelection$ = this.dataSelectionSource.asObservable();

    public rowsLength = 100;

    public loading_state = false;

    public getSelected(filterVisible = true) {
        // return  Object.values(this.selection_map).filter(_ => filterVisible ? _.is_displayed : true).map(_ => _.row);
       /* fix to keep order of table when consuming selection  */
        return this._row_index.map(id => this.selection_map[id]).filter(r =>r!==undefined).filter(_ => filterVisible ? _.is_displayed : true).map(_ => _.row);
      
    }


    public registerSorter(sorter$: Observable<Sort> = NEVER) {
        this._sorter$ = sorter$.pipe(tap((event: Sort) => {
            this.filters.find(f => f.name == event.active).sortType = event.direction == 'asc' ? 1 :
                (event.direction == 'desc' ? 2 : 0);
            this._page_number = 0;
            console.log(this.filters)

        }));
    }
    public registerPaginator(paginator$: Observable<PageEvent> = NEVER, ) {
        this._paginator$ = paginator$.pipe(tap((event: PageEvent) => {
            this._page_number = event.pageIndex
            this._page_size = event.pageSize;
        }));

    }

    public loadTable(config_definition: any, initialPage, initialSize) {
        this._page_size = initialSize;
        this._page_number = initialPage;
        const initData = this.tableDataService.initTableData(config_definition);
        this.registerFilters(initData.defaultFilters || []);
    }

    public getRowStream$() {
        return this.dataRowsSource.asObservable();
    }

    public init(config_definition, initialPage = 1, initialSize = 100, paginator$: Observable<PageEvent> = NEVER, sorter$: Observable<Sort> = NEVER) {
        this.loadTable(config_definition, initialPage, initialSize);
        this.registerPaginator(paginator$);
        this.registerSorter(sorter$);
        /* make sure that getRows is placed on event queue (don't return just of(....) ) so the subscription will not miss the result */
        this.setUpObservable(); 
        return this.getRowStream$();
    }

    public destroyService() {
        unsubscribeMap(this.subs);
        this.storeService.removeItem(this.store_uid);
    }

    private setUpObservable() {
        unsubscribeMap(this.subs);
        this.subs['events'] = merge(this._paginator$, this._sorter$).pipe(
            startWith({}),
            map(v => {
                return v;
            }),
            mergeMap(a => {
                
                return this.getRows().pipe(map(() => a))
            })
        ).subscribe(v => true);
    }


    public registerSelectionModel(selectionChangeObs$: Observable<any>, idKey: string) {
        this.keyId = idKey;
        return selectionChangeObs$.pipe(tap((change) => {


            this.emitSelectionChange(change.added, change.removed);
        }), map(changes => {
            changes.currentPageSelection = this.getCurrentPageSelection();
            return changes;
        }));
    }


    private _rows = [];
    public extraOptions: any = {};
    public getRows(): Observable<any> {
        this.loading_state = true;
        return this.tableDataService.getRows(this._page_number, this._page_size, this.filters, this.extraOptions).pipe(tap((raw) => {
            this._total_record = raw.total_record;
            this.loading_state = false;
            this._rows = raw.data;
            this._row_index = this._rows.map(r =>r[this.keyId]);
            this.emitSelectionChange();
        }), map((v: any) => this.dataRowsSource.next({
            total_pages: v.total_page,
            rows: v.data,
            current_page: v.current_page,
            total_rows: v.total_record,

        })));
    }

    private registerFilters(filters = undefined) {
        this.store_uid = filters == undefined ? this.storeService.refreshItem(this.store_uid) : this.storeService.addItem(filters); // but keep sorting
        this.filters = this.storeService.getItem(this.store_uid).ref;
    }

    public getCurrentPageSelection() {
        return this.currentPageSelection;
    }

    public refreshTable() {
        this.selection_map = {};
    }

    public getSelectionOnPage(page, pageSize) {
        let rows = 0;
        Object.keys(this.selection_map).forEach(key => {
            if (this.selection_map[key].is_displayed) {
                rows++;
            }
        });
        return rows;
    }

    private emitSelectionChange(added = [], removed = []) {
        const idKey = this.keyId;
        let changed = false;
        
        added.forEach((key, i) => {
            changed = true;
            const row_ = (this._rows.find(k => k[idKey] == key));
            this.selection_map[key] = { row: row_, is_displayed: null };
        });
        removed.forEach((key, i) => (delete (this.selection_map[key]), changed = true));
        this.currentPageSelection = intersectionBy(this._rows.map(r => r[idKey].toString()), Object.keys(this.selection_map));

        /* toggle display flag */
        Object.keys(this.selection_map).forEach(key => {
            const orig = this.selection_map[key].is_displayed;
            this.selection_map[key].is_displayed = this.currentPageSelection.includes(key);
            changed = orig != this.selection_map[key].is_displayed ? true : changed;
        });
        if (changed) this.dataSelectionSource.next(this.selection_map);
        return this.selection_map;
    }

    /**
     * used to pass data to the 'data' attribute when creating advanced filter mat dialog
     */
    public advanced_filter_data = {};



}
