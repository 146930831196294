<div class="note">
    <div class="note-textarea-wrap">
        <textarea [(ngModel)]="noteValue" placeholder="Type your comment here..." class="text-comment"></textarea>
        <div *ngIf="showSaveIndicator" class="loading-state"></div>
    </div>

    <mat-card-actions>
        <div class="buttons-options">
            <button mat-button color="warn" (click)="deleteNote()">Delete</button>
            <button mat-button (click)="saveNote()">Save</button>
        </div>
    </mat-card-actions>
</div>