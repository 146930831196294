import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import {isString as _isString} from 'lodash-es'

export interface DialogData {
  message: object;
  name: string;
}

/**
 * @title Dialog Overview
 */
@Component({
  selector: 'alert-popup',
  templateUrl: 'alert-popup.component.html',
  styleUrls: ['alert-popup.component.scss'],
  standalone:true,
  imports:[MatDialogModule, MatIconModule, CommonModule]
})

export class AlertPopup {

    public message: any;
    public defaultTitle = 'Alert!';
    public cssType = 'danger';
    public widthSize = '500px';
    public handleString : boolean = false;
    public errors = {next:false, prev:false, stack:[], currIndex:undefined};

    constructor(
      public dialogRef: MatDialogRef<AlertPopup>,
      @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {
        this.errors.stack = Array.isArray(data) ? data : [data]; 
        this.displayMessage(0);
        dialogRef.disableClose = true;
       
    }

    private displayMessage(index:number){
      this.errors.currIndex =  index;
      const _message = this.errors.stack[index];
      this.message = _message;
      this.handleString = _isString(_message);
    }

    ngOnInit(): void {
        this.dialogRef.updateSize(this.widthSize);
    }
    ngDoCheck(): void {
    
      const index = this.errors.currIndex;
      if(index === undefined)return;
      this.errors.next = index < this.errors.stack.length - 1;
      this.errors.prev = index > 0;
    }
    public nextMessage(){
      this.displayMessage(++this.errors.currIndex);
    }

    public prevMessage(){
      this.displayMessage(--this.errors.currIndex);
    }

    closeMe():void {
      this.dialogRef.close();
    }

    public reloadApp(goHome?:boolean){
      goHome ? window.location.href = "/"   : location.reload();
    }
 
}
