import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit, TemplateRef } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';

@Component({
    selector: 'filter-modal',
    templateUrl: './filter-modal.component.html',
    styleUrls: ['./filter-modal.component.scss'],
    standalone: true,
    imports: [MatDialogModule, MatIconModule, MatFormFieldModule,
        MatDatepickerModule, MatNativeDateModule, FormsModule,
        CommonModule, MatOptionModule]
})
export class FilterModalComponent implements OnInit {
    public filters: any;
    public FROM = 'from';
    public TO = 'to';
    public templateTop: TemplateRef<any>

    constructor(public dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public data: any) {
        this.templateTop = this.data.templateTop || null;
    }

    ngOnInit() {
        this.filters = this.data.service.getAdvancedFilterConfig(this.data.columns);

    }

    public closeDialog(): void {
        this.dialogRef.close();
    }

    public clearFilters(): void {
        this.data.service.clearFilters();
        this.dialogRef.close();
    }

    public apply(): void {
        this.data.service.applyAdvancedFilters(this.filters.options);
        this.dialogRef.close();
    }
}