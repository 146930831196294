<div class="multiselect-wrap" (clickOutside)="showOptions = false">
    <div class="multiselect-trigger" (click)="showOptions = !showOptions" [matTooltip]="tooltip">
        <span class="multiselect-trigger__icon {{icon}}"></span>
        <span class="multiselect-trigger__arrow"
            [ngClass]="{'ri-arrow-down-s-fill': !showOptions, 'ri-arrow-up-s-fill': showOptions}"></span>
        <span class="multiselect-trigger__count">{{selectedCount}}</span>
    </div>
    <div *ngIf="showOptions" class="multiselect-values">
        <p (click)="toggleAll()"> <span [ngClass]="{'ri-checkbox-indeterminate-line' :toogleAllStatus == 'SOME', 'ri-checkbox-line': toogleAllStatus =='ALL', 'ri-checkbox-blank-line'  :toogleAllStatus =='NONE' }"> </span>
            {{label }}
        </p>
        <p *ngFor="let opt of options" (click)="toggleOption(opt)">
            <span
                [ngClass]="{'ri-checkbox-blank-line': !isOptionSelected(opt),'ri-checkbox-line': isOptionSelected(opt)}"></span>
            {{opt.name}}
        </p>
    </div>
</div>