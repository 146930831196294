import { Component, OnInit } from '@angular/core';
import { FirebaseAuthService } from '../../services/fbauth.service';
import { Router } from '@angular/router';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss'],
  standalone: true,
  imports: [MatMenuModule, MatTooltipModule]
})
export class AppHeaderComponent implements OnInit {
  public isLoggedIn: boolean;

  /**
   * I added this menu, because its showing error as you see menu is used in html component
   */
  public menu: boolean;
  public is_authenticated_user: boolean = false;
  public userImageUrl: string;
  public userTooltip: string;
  constructor(
    public authService: FirebaseAuthService,
    private router: Router,
  ) {
    this.authService.checkAuthentication().subscribe(current_user => {
      if (current_user) {
        this.userImageUrl = current_user.photoURL;
        this.userTooltip = current_user.email;
        this.is_authenticated_user = true;
      }
      else {
        this.userImageUrl = '';
        this.is_authenticated_user = false;
      }
    });
  }
  signOut(): void {
    // this.authService.logout();
    this.authService.signOut().then(v => this.router.navigate(['/welcome']));
  }

  public signIn() {
    this.authService.googleSignIn();
  }

  ngOnInit() {

  }

}