<div class="app-ibutton-container">
    <button [matTooltip]="tooltip" class="ibutton {{elClass}}" [ngClass]="{'with-dropdown': hasDropdown, 'ibutton-disabled': disabled}">
        <span class="ibutton-icon {{icon}}"></span>
        <span *ngIf="label" class="ibutton-label">{{label}}</span>

        <span *ngIf="hasDropdown" 
              class="dropdown-icon ri-arrow-drop-down-fill"
              (click)="dropdownTriggerClick($event)">
        </span>
    </button>
    <div *ngIf="hasDropdown" class="ibutton-dropdown-menu">
        <ng-content></ng-content>
    </div>
</div>
