<div class="summary-modal">
    <button class="close-button" (click)="closeModal()"><mat-icon svgIcon="close-outline"></mat-icon></button>

    <div class="summary-modal__header">
        <p class="f-small">Item</p>
        <p class="f-large">Description</p>
        <p class="f-small">Unit Cost</p>
        <p class="f-small">Quantity</p>
        <p class="f-small">Price</p>
    </div>

    <div class="summary-modal__items">
        <div class="item" *ngFor="let item of summaryItems | keyvalue">
            <p class="f-small">{{item.key}}</p>
            <p class="f-large">{{item.value.description}}</p>
            <p class="f-small">{{listItemsMap[item.key]?.price  | number }}</p>
            <p class="f-small">{{item.value.item_count}}</p>
            <p class="f-small">{{listItemsMap[item.key]?.price*item.value.item_count  | number}}</p>
        </div>
    </div>

    <div class="summary-modal__footer">
        <h3>Subtotal: <span>{{sub_total | number}}</span></h3>
    </div>
</div>