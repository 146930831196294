import { isString as _isString, isArray as _isArray } from 'lodash-es';

export class Filter {
    columnNumber: number;
    name: string;
    sortType?: SortType;
    validValues?: string[];
    operators: string[];
    value: string;
    selectedOperator: string;
    selectedValues?: string[] = [];
    selectedDate?: SelectedDate = {
        start: '',
        end: ''
    };

    defaultFilter?: any;
    misc?: any;
}

export interface tableFindRequest {
    filters: Filter[];
    requestExtraOptions?: any;
}

export enum SortType {
    none = 0,
    asc = 1,
    desc = 2
}

export class ChangeFilterSortModel {
    previousSortType: SortType;
    currentSortType: SortType;
}

export interface SelectedDate {
    start: any;
    end: any;
}

export function removeFilterCondition(filter: Filter, removeSort: boolean = false) {

    filter.selectedOperator = '';
    if (removeSort) {filter.sortType = SortType.none; }

}

export function addFilterCondition(filter: Filter, operator: string, value: any) {

    const operatorsToValMap = {
        like: (filter, val) => filter.value = val,
        '=': (filter, val) => filter.value = val,
        in: (filter, val) => filter.selectedValues = _isString(val) ? val.split(',') : (_isArray(val) ? val : null),
        between_date: (filter, val) => filter.selectedDate = val ? val : {},
    };
    if (filter.operators.indexOf(operator) >= 0) { filter.selectedOperator = operator; }
    else { filter.selectedOperator = filter.operators[0]; } // for now I will just do that
    operatorsToValMap[filter.selectedOperator](filter, value);

}

export function getFilterCondition(filter: Filter, operator: string, convertToString: boolean = false) {

    const operatorsToValMap = {
        like: (filter) => filter.value,
        '=': (filter) => filter.value,
        in: (filter) => filter.selectedOperator,
        between_date: (filter) => filter.selectedDate,
    };
    if (filter.operators.indexOf(operator) >= 0) { filter.selectedOperator = operator; }
    else { filter.selectedOperator = filter.operators[0]; } // for now I will just do that
    return operatorsToValMap[filter.selectedOperator](filter);
}

export function cleanFilters(filters: Filter[]): Filter[] {
    const result =  filters.map(f => {
        if (!(f.value || f.selectedDate.end || f.selectedDate.start || f.selectedValues.length > 0)) {
            f.selectedOperator = '';
        }
        if (!f.selectedOperator) {
            f.value = '';
            f.selectedValues = [];
            f.selectedDate = {start: '', end: ''};
        }
        return f;
    });
    return result;
}


export function  updateSortingOfFilters(filters: Filter[]): Filter[] {
    return filters.sort((one, two) => {
      const firstSorted = one.sortType !== SortType.none;
      const secondSorted = two.sortType !== SortType.none;
      let result = 0;
      if (firstSorted && !secondSorted) {
        result = -1;
      }
      if (!firstSorted && secondSorted) {
        result = 1;
      }
      if ((firstSorted && secondSorted) || (!firstSorted && !secondSorted)) {
        result = 0;
      }

      return result;
    });
  }
