import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ClickOutsideDirective } from "../../directives";

@Component({
    selector: 'app-dropdown-button',
    templateUrl: './dropdown-button.component.html',
    styleUrls: ['./dropdown-button.component.scss'],
    host: {
        "[style.pointer-events]": "disabled ? 'none' : 'auto'"
    },
    standalone: true,
    imports: [CommonModule, MatTooltipModule,ClickOutsideDirective]
})
export class DropdownButtonComponent implements OnInit {
    @Input() icon: string = 'ri-equalizer-line';
    @Input() options: DropdownOption[] = [];
    @Input() value: DropdownOption | null;
    @Input() tooltip: string;
    @Input() displaySelection: boolean = true;
    @Output() onChange: EventEmitter<DropdownOption> = new EventEmitter();

    @Input() disabled: boolean = false;


    public showOptions: boolean = false;

    public multiOptions: DropdownOption[] = []


    constructor() { }

    ngOnInit() { }

    public selectOption(opt: DropdownOption) {
        this.value = opt;
        this.showOptions = false;
        this.onChange.emit(this.value);
    }

    public isInMultiOptoin(opt: DropdownOption) {
        return this.multiOptions.find(x => x.value == opt.value)
    }


}

export interface DropdownOption {
    name: string;
    value: any | null;
    selected?: boolean;
}

