import { Injectable, NgZone } from '@angular/core';
import { Observable, Subscription, from, of } from 'rxjs';
import { Router } from '@angular/router';
import {
    AuthProvider, GoogleAuthProvider, User, UserCredential,
    createUserWithEmailAndPassword,
    getAuth,
    getIdTokenResult,
    onAuthStateChanged,
    sendEmailVerification,
    signInWithEmailAndPassword, signInWithPopup, signOut
} from 'firebase/auth';
import { map, take } from 'rxjs/operators';


@Injectable({
    providedIn: 'root',
})
export class FirebaseAuthService {
    afAuth: any;


    constructor(
        private router: Router,
        private ngZone: NgZone,
    ) {

    }



    public userSubscriptions: Array<Subscription | false> = [];


    private postAuth_navigation: any = null;
    public setPostAuthUrl(navigation) {
        this.postAuth_navigation = navigation;

    }
    public navigatePostAuth() {

        if (!this.postAuth_navigation) return null;
        this.ngZone.run(() => {
            this.router.navigate(this.postAuth_navigation).then(
                s => {
                    this.postAuth_navigation = null;
                },
                e => console.log(e)
            )
        })
    }

    private oauthIdToken;

    private auth_
    public googleSignIn(): Promise<UserCredential> {
        const provider = new GoogleAuthProvider();
        // provider.addScope('https://www.googleapis.com/auth/cloud-platform')

        return this.doSignInPopUp(provider);
    }

    public emailSignIn(email: string, pass: string) {
        return signInWithEmailAndPassword(getAuth(), email, pass).then(result => {

            this.navigatePostAuth();
        },
            error => {
                console.log(error);
                throw error;
            });
    }

    private doSignInPopUp(provider: AuthProvider): Promise<UserCredential> {
        return signInWithPopup(getAuth(), provider)
            .then(result => {
                this.navigatePostAuth();
                return result;
            },
                error => {
                    console.log(error);
                    return error;
                }
            )
    }



    public signOut(): Promise<void> {
        this.userSubscriptions.filter(sub => sub != false).forEach((x: Subscription) => x.unsubscribe());
        return signOut(getAuth()).then(success => {
            return success;
        })
    }


    public addEmailUser(email: string, pass: string) {
        return createUserWithEmailAndPassword(
            getAuth(), email, pass)
            .then(({ user }) => sendEmailVerification(user))
            .then(h => null /*  console.log(h) */);

    }


    public checkAuthentication(): Observable<User> {
        return new Observable(observer => {
            onAuthStateChanged(getAuth(), user => observer.next(user))
        })
    }


    public hasAccess(allowedTokens: string[], opts: { checkMode: 'any' | 'all' }): Observable<boolean> {
        const user = getAuth().currentUser;
        if (user) {
            return from(user.getIdTokenResult()).pipe(
                map(({ claims }) => {
                    if (opts.checkMode === 'any') {
                        // Perform the check using .some - true if any token is present
                        return allowedTokens.some(t => claims[t] !== undefined);
                    } else if (opts.checkMode === 'all') {
                        // Perform the check using .every - true only if all tokens are truthy
                        return allowedTokens.every(t => !!claims[t]);
                    } else {
                        // Fallback or error handling if checkMode is not recognized
                        throw new Error("Invalid check mode specified.");
                    }
                })
            );
        } else {
            return of(false);
        }
    }





}
