import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { UserAuthData } from '../models/userAuth.model';
import { GlobalService } from '../services/global.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public currentUserAuthDataBehavior = new BehaviorSubject<UserAuthData>(this.getSavedUserAuthData());
  public isValidUserAuthDataBehavior = new BehaviorSubject<boolean>(this.checkValidUserAuthData());
  private username:string = ""; 
 
  


  constructor(private httpClient: HttpClient, private router :Router, private globalService: GlobalService) { }

  public checkValidUserAuthData(): boolean {
    const userDate = this.currentUserAuthDataBehavior.getValue();
    // const isAuthorized = userDate !== null && userDate.access_token && userDate.expires_at > new Date();
    const isAuthorized = userDate !== null; 

    return isAuthorized;
  }

  // public getLatestToken (): Observable <boolean> {
   
  //   const userDate = this.currentUserAuthDataBehavior.getValue();
  //   if(!userDate)return of(false);
  //   if(userDate.expires_at <= new Date()){
  //     return this.refreshToken().pipe(
  //       map (result =>{
  //         return result;
  //       })
  //     )
  //   }
  //   return of(true);
  // }

  private getSavedUserAuthData(): UserAuthData {
    let currentUserAuthData: UserAuthData = null;

    const userAuthDataString = localStorage.getItem('currentUserAuthData');

    if (userAuthDataString) {
      currentUserAuthData = JSON.parse(userAuthDataString);
      currentUserAuthData.expires_at = new Date(currentUserAuthData.expires_at);
    }

    return currentUserAuthData;
  }

  private handleTokenResponse = (user: UserAuthData) =>  {
    if (!user || !user.access_token) {
      this.isValidUserAuthDataBehavior.next(false);
      return false;
    }
    const expiresAt = new Date();
    const expiresAtMilliseconds = expiresAt.getMilliseconds() + user.expires_in;
    expiresAt.setMilliseconds(expiresAtMilliseconds - 1000 * 60 * 15); // refresh 15 minutes before 
    
    user.expires_at = expiresAt;
    user.username = this.username;
    localStorage.setItem('currentUserAuthData', JSON.stringify(user));
    this.currentUserAuthDataBehavior.next(user);

    this.isValidUserAuthDataBehavior.next(true);


    return true;
  }

  
  public logout(): void {
    localStorage.removeItem('currentUserAuthData');
    this.currentUserAuthDataBehavior.next(null);
    this.isValidUserAuthDataBehavior.next(false);
  }
}
