<header class="container-fluid bg-white p-3 mat-elevation-z4">
  <div class="row">
    <div class="col col-1"></div>
    <div class="col col-5">
      <a href="#">
        <img src="assets/img/svg/login-logo.svg">
      </a>
    </div>
    <div class="col col-4">
      <ng-content></ng-content>
    </div>
    <div class="col col-2">

     
      <button color='primary' mat-raised-button *ngIf="!is_authenticated_user" (click)='signIn()'
        matTooltip="Info about the action">
        Sign In
      </button>
     
     
      <span matTooltip="userTooltip" [matTooltip]="userTooltip" style="border-radius: 25%;" [matMenuTriggerFor]="menu">
        <img *ngIf="is_authenticated_user" style="border-radius: 50%;" [src]="userImageUrl" width="32" height="32">
      </span>

      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)='signOut()'>Sign Out</button>
      </mat-menu>
      

    </div>
  </div>
</header>