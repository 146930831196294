import { Injectable } from "@angular/core";
import { get as _get, set as _set, cloneDeep, isArray, isEmpty, isEqual, isObject, transform } from 'lodash-es';
import { NEVER, Observable, of } from "rxjs";
import { isSame } from 'src/app/utils';
import { v4 as uuidv4 } from 'uuid';


/**
 * Find difference between two objects
 * @param  {object} origObj - Source object to compare newObj against
 * @param  {object} newObj  - New object with potential changes
 * @return {object} differences
 */
function difference(origObj, newObj) {
  function changes(newObj, origObj) {
    let arrayIndexCounter = 0
    return transform(newObj, function (result, value, key) {
      if (!isEqual(value, origObj[key])) {
        let resultKey = isArray(origObj) ? arrayIndexCounter++ : key
        result[resultKey] = (isObject(value) && isObject(origObj[key])) ? changes(value, origObj[key]) : value
      }
    })
  }
  return changes(newObj, origObj)
}


export interface StoreItem {
    clone:any,
    ref:any
}

@Injectable({
    providedIn: "root"
})
export class DollarStoreService {


    private store_ = new Map<any|symbol, StoreItem>();

    public addItem(val: any, key: string = undefined, clone = true) :string{
        // key = key || Symbol();
        key = key || uuidv4();
        const cloned = clone ? cloneDeep(val) : val
        this.store_.set(key, {clone:cloned,ref:val});
        return key;
    }
    public getItem(key: string|symbol) :StoreItem {
        
        return this.store_.get(key)
    }
    public removeItem(key){
        // console.log(this.store_.size,'<-- is ths size of the store service');
        return this.store_.delete(key);
    }


    
    
    /**
     * 
     * @param key1 merges items from key 2 into the item at key1. return a clean item 
     * @param key2 
     * @param path 
     * @param useClone default uses ref in key 2. id false 
     */
    public mergeItems(key1,key2,path:string[]=[],useClone=false){
        const branch = useClone ? 'clone' : 'ref';
        return this.update(key1,this.getItem(key2)[branch],path);
    }

    public refreshItem(key){
       const newKey = this.addItem(this.getItem(key).clone);
       this.removeItem(key);
       return newKey;
    }


    /**
     * update the original value (the clone and the ref) with value at path.  if no path the value is updated but like with path the value is now clean ( not dirty)
     * @param key 
     * @param v 
     * @param path 
     */
    public update(key, v:any ,path:string[]){        
        const newVal = path.length > 0 ? _set(this.store_.get(key).clone,path,v) : v;
        this.removeItem(key);
        return this.addItem(newVal,key);

        
    }

    /**
     * 
     * @param key runs lodash is equal on key and compared object ignoring array order . compares to ref by default
     * @param compareObj 
     */
    public isDirty(key: string, compareObj: any = undefined, path:string[]=undefined, comparer= undefined) {
        const storeObj = this.store_.get(key);
        if(isEmpty(storeObj)) return undefined;
        compareObj = compareObj || storeObj.ref;
        const result = !(isEmpty(compareObj) ? undefined : isSame(path? _get(storeObj.clone,path):storeObj.clone, path? _get(compareObj,path):compareObj,comparer)); // look into angular keyValueDiffers 
        // if(result)console.log(difference(path? _get(storeObj.clone,path):storeObj.clone, path? _get(compareObj,path):compareObj));
        return result;
    }



    /* give original and object to compare to */
    public compareEvents$(key: string, comparerObj): Observable<any> {
        let i = 0; 
        const original = this.getItem(key);
        if (!(original&&comparerObj)) return NEVER;
        // console.log(comparerObj); to implement

        // _onChange(comparerObj, (path, value, previousValue) => {
        //     console.log('Object changed:', ++i);
        //     console.log('path:', path);
        //     console.log('value:', value);
        //     console.log('previousValue:', previousValue);
        // });

        return of(null);
    }

    /* a better idea so it does not run on every do on change 
    
    private dirty_ = {};
   
    public isDirty(key_: any, compareObj_: any = undefined, path_: string[] = undefined, async = true , debounceTime = 100) {

        const executer_ = (key: string, compareObj: any = undefined, path: string[] = undefined) => {
            console.log('talking to me bitch??')

            const storeObj = this.store_.get(key);
            if (isEmpty(storeObj)) return undefined;
            compareObj = compareObj || storeObj.ref;
            return !(isEmpty(compareObj) ? undefined : isSame(path ? _get(storeObj.clone, path) : storeObj.clone, path ? _get(compareObj, path) : compareObj)); // look into angular keyValueDiffers 
        }

        if (!async) {
            this.dirty_[key_]['isDirty'] = executer_(key_, compareObj_, path_);
        } else {
            if (!this.dirty_[key_]['running']) {
                this.zone.runOutsideAngular(__ => {
                    this.dirty_[key_]['running'] = setTimeout(() => {
                        this.dirty_[key_]['isDirty'] = executer_(key_, compareObj_, path_);
                        this.dirty_[key_]['running'] = false;
                    }, debounceTime)
                });



            }
        }
        return this.dirty_[key_]['isDirty'];

    } */
}