<h2 class="modal-title" mat-dialog-title>Filter settings <span class="close-icon" (click)="closeDialog()">
        <mat-icon>close</mat-icon>
    </span></h2>
<div class="filter-modal-content" mat-dialog-content>
    <ng-container *ngTemplateOutlet="templateTop; context:{$implicit:filters}"></ng-container>
    
    <div class="side-by-side-container" *ngFor="let filter of filters.options; index as i">
        <div class="one-third">
            <p>{{filter.label}}</p>
        </div>

        <div class="one-third">
            <ng-container *ngIf="filter.type == 'date'">
                <mat-form-field>
                    <mat-label>Choose a date</mat-label>
                    <input matInput [matDatepicker]="iFROM" [(ngModel)]="filter.selectedDate.start">
                    <mat-datepicker-toggle matSuffix [for]="iFROM"></mat-datepicker-toggle>
                    <mat-datepicker #iFROM></mat-datepicker>
                </mat-form-field>
            </ng-container>
            <ng-container *ngIf="filter.type == 'string'">
                <mat-form-field>
                    <mat-select [(ngModel)]="filter.selectedOperator">
                        <mat-option *ngFor="let op of filter.operators" [value]="op">{{op}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </ng-container>
        </div>

        <div class="one-third">
            <ng-container *ngIf="filter.type == 'date'">
                <mat-form-field>
                    <mat-label>Choose a date</mat-label>
                    <input matInput [matDatepicker]="iTO" [(ngModel)]="filter.selectedDate.end">
                    <mat-datepicker-toggle matSuffix [for]="iTO"></mat-datepicker-toggle>
                    <mat-datepicker #iTO></mat-datepicker>
                </mat-form-field>
            </ng-container>

            <ng-container *ngIf="filter.type == 'string'">
                <mat-form-field>
                    <input matInput [(ngModel)]="filter.value">
                </mat-form-field>
            </ng-container>
        </div>
    </div>
</div>
<div class="filter-modal-footer">
    <div class="clear" (click)="clearFilters()">CLEAR FILTERS</div>
    <div class="apply" (click)="apply()">APPLY</div>
</div>