import { Injectable, TemplateRef } from '@angular/core';
import { Observable, Subject,BehaviorSubject, of  } from 'rxjs'
import { debounceTime, filter, map,tap,delay } from 'rxjs/operators';



export interface viewElementMessage {
    key: string;
    val: string | boolean | number | TemplateRef<any>;
}

@Injectable({
    providedIn: "root"
})
export class ViewCommunicationService {
    /* this service is made to allow child views to update parent views and not  trigger change detection changed after checked warning. think about how to make this into a directive  */

    constructor(){
    }
    private source = new Subject<viewElementMessage>();
    private defaultValueMap = {};
    /* delay(1) is only to trick angular to call changed detection again so we don't get changed after view checked error. I need a better solution and understand it better.  
         so I think i get the gist of change detection but the behavior is still a bit chaotic. you would think that you can update parent bindings in child constructor / ngOnInit hooks
         since this happens before the parent view is updated. However the behavior is not consistent. I seems to work one level down but not nested two levels. and sometimes it allows in constructor 
         and not in onInit, sometimes in both.  Also what are the roles of resolvers? when are the resolver dependent components checked.  Maybe a clearer way to do this hack is to call check detection directly and not rely 
         on the fact that it is a byproduct of delay time (i.e setInterval)

         so it turns out  the radiology report editor is created after toolbar which is why you could change in child. however b-read is created after. ahha 
       */
    private source$ = this.source.asObservable().pipe(delay(1)); //think about adding some buffer time to collect messages first 
    

    public getSubscription$(_key: string, defaultVal = undefined): Observable<string | boolean | number | TemplateRef<any>> {
        this.defaultValueMap[_key] = defaultVal;
        return this.source$.pipe(filter(message => message.key === _key), map(_ => _.val)/* ,tap(v=>console.log(v)) */);
    
    }

    /**
     * @todo add mechanism to send message when caller is destroyed. for now the caller has to clear it by calling clear message
     * @param message viewElementMessage
     */
    public postMessage(message: viewElementMessage): void {
   
        this.source.next(message);
    }

    /**
     * set the state of whoever subscribed to this key to its default value 
     * @param key string
     * @todo perhaps we want here some simple state management 
     */
    public clearMessage(_key: string) {
        this.source.next({ key: _key, val: this.defaultValueMap[_key] });
    }

    public rawMap :any = {};
}