import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { NoteService } from '../../services';
import { of } from 'rxjs/internal/observable/of';
import { MatCardModule } from '@angular/material/card'
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
@Component({
  selector: 'app-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.scss'],
  standalone: true,
  imports: [MatCardModule, FormsModule, CommonModule]

})
export class NoteComponent implements OnInit {

  @Input()
  modelId: number;

  @Input()
  modelName: string;

  public showSaveIndicator: boolean;
  public noteValue: string;
  public noteExists: boolean;
  // private subs = {};

  constructor(private noteService: NoteService) {
    this.showSaveIndicator = false;
    this.noteExists = false;
  }

  ngOnInit() {
    this.getExistingNote();
  }


  // ngOnChanges(changes: SimpleChanges): void {
  //   if (changes.modelId.currentValue) {
  //     this.saveNote();
  //   }
  // }

  // ngOnDestroy(): void {
  //   unsubscribeMap(this.subs);
  // }

  public getExistingNote(): void {
    this.noteService.getNote(this.modelId).subscribe((res) => {
      if (res) {
        this.noteExists = true;
        this.noteValue = res.note;
      }
    }, (err) => {
      if (err.status == 404) {
        this.noteExists = false;
        return of('');
      }
      throw 'invalid request';
    });
  }

  public deleteNote(): void {
    this.showSaveIndicator = true;
    this.noteValue = '';
    this.noteService.putNote(this.modelId, this.noteValue).subscribe(v => {
      this.showSaveIndicator = false;
    });
  }

  public saveNote(): void {
    this.showSaveIndicator = true;
    if (this.noteExists) {
      this.noteService.putNote(this.modelId, this.noteValue).subscribe(v => { console.log(v); this.showSaveIndicator = false; });
    } else {
      this.noteService.postNote(this.modelId, this.noteValue).subscribe(v => { console.log(v); this.noteExists = true; this.showSaveIndicator = false; });
    }
  }

  // public saveNote(): void {
  //   this.noteForm.disable();
  //   this.showSaveIndicator = true;
  //   unsubscribeMap(this.subs);
  //   let ignore_count = 0;
  //   const streams = this.noteService.register(this.modelId, this.modelName);
  //   this.subs['downstream'] = streams.downstream.subscribe(incoming => {
  //     if(incoming && incoming.length > 0) ignore_count++;
  //     this.noteForm.setValue(incoming)
  //     this.noteForm.enable();
  //     this.showSaveIndicator = false;
  //   });
  //   this.subs['upstream'] = streams.upstream(this.noteForm.value).subscribe(v => console.log(v)); 
  // }
}
