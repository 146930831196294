import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'error-pages',
  templateUrl: './error-pages.component.html',
  styleUrls: ['./error-pages.component.scss'],
  standalone:true
})
export class ErrorPagesComponent implements OnInit, OnDestroy {
  public code = '404';
  public message = 'The Page can\'t be found\'';
  private sub: Subscription;
  constructor(private route: ActivatedRoute) {}

  public ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      if (params.code) {
        this.code = params.code;
        this.message = params.code === '403' ? 'The page or resource you were trying to reach is forbidden' : this.message;
      }
    });
  }

  public ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
}
