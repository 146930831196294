import { Injectable } from '@angular/core';
import { Observable, of, merge } from 'rxjs';
import { map, mergeMap, startWith, catchError, tap, filter ,concatMap, debounceTime, delay} from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { GetNoteResponseModel, UpdateNoteResponseModel, DeleteNoteResponseModel } from '../models';
import { NotificationService } from './notification.service';
import { GlobalService } from './global.service';

@Injectable({
  providedIn: 'root'
})

export class NoteService {
  private serverURL: string = environment.serverURL;

  constructor(private httpClient: HttpClient, private notificationService: NotificationService, private globalService: GlobalService) {

  }

  private apiUrl = (id, table) => `${this.serverURL}/api/${table}/${id}/note`;

  public getNote(id: number, table = 'study_service'): Observable<any> {
    return this.httpClient.get(this.apiUrl(id, table), { headers: { TSW_ACCEPT_ERROR: "404" } })
      .pipe(map(response => response['data']));
  }
  public postNote(id: number, body: string, table = 'study_service'): Observable<any> {
    return this.httpClient.post<any>(this.apiUrl(id, table), {
      note: body,
    });
  }
  public putNote(id: number, body: string, table = 'study_service'): Observable<any> {
    return this.httpClient.put<any>(this.apiUrl(id, table), { note: body });
  }


  public deleteNote(id: number): Observable<DeleteNoteResponseModel> {
    return this.httpClient.delete<DeleteNoteResponseModel>(`${this.serverURL}/api/note/${id}`);
  }



  // public register(id, model): { upstream: (data: string) => Observable<any>, downstream: Observable<any> } {
  //   let note_id = -88;
  //   let put_flag = false;

  //   const post_ = (id, data) => this.postNote(id, data).pipe(tap(res => {
  //     note_id = res.data.id;
  //     put_flag = true;
  //   }));
  //   const fetchNote$ = (notificationData: any) => this.getNote(id).pipe(map(response => {
  //     put_flag = true;
  //     note_id = response.id
  //     return response.note;
  //   }),
  //     catchError((err: string) => {
  //       if (JSON.parse(err.substring('Error Code:'.length)).status == 404) {
  //         put_flag = false;
  //         return of('');
  //       }
  //       throw 'invalid request';
  //     }),delay(800));

  //   const update_ = (data) => put_flag ? this.putNote(id, data) : post_(id, data);

  //   const buildNotifyStream = (id, model) => this.notificationService.apiUpdated$.pipe(
  //     startWith({ items: [{ table: 'notes', type: 'saved', id: note_id }] }),
  //     filter(notificationData => notificationData.items.some(item => item.table == 'notes' && item.id == note_id)),
  //     concatMap(data => fetchNote$(data))
  //   );

  //   return { upstream: update_, downstream: buildNotifyStream(id, model) }
  // }
}
