import { ElementRef, inject } from '@angular/core';
import { FormControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogConfig } from '@angular/material/dialog';
import { GridApi } from 'ag-grid-community';
// import * as  md5 from 'blueimp-md5';
// import { saveAs } from 'file-saver';
import { CollectionReference, DocumentChange, DocumentChangeType, DocumentReference, DocumentSnapshot, Query, QuerySnapshot, doc, getFirestore, onSnapshot } from 'firebase/firestore';
import {
  castArray as _castArray,
  difference as _difference, forEach as _forEach,
  forOwn as _forOwn,
  isArray as _isArray,
  isEqual as _isEqual,
  isEqualWith as _isEqualWith,
  isPlainObject as _isPlainObject, isString as _isString,
  startCase as _startCase, toLower as _toLower,
  isArray,
  isObject,
  isString
} from 'lodash-es';
import moment from "moment"; import { Observable, Subscription, merge, pipe } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ConnectorPath } from './modules/shared/services/firestore-data.service';
import { User, getAuth, onAuthStateChanged } from 'firebase/auth';
import { md5 } from './md5';
import { MatSnackBar } from '@angular/material/snack-bar';


export interface DocumentChangeAction<T> {
  type: DocumentChangeType;
  payload: DocumentChange<T>;
}

export function prevXwkDays(x: number, wkDay_?: number): number {
  const wkDay = wkDay_ || moment().day();
  // const extra = wkDay >= x ? 0 : (wkDay == 0 ? 1 : 2) ;
  const extra = wkDay > x ? 0 : (wkDay == 0 ? 1 : wkDay);
  return x + extra
}


/**
 * converts a time range to to be start,end of day ie: 12:00:00:01 am - 11:59:59 pm local time 
 * @param start 
 * @param end 
 */
export function getDaysOfTimeRange(start_: Date, end_: Date) {

  return {
    start: new Date(start_.setHours(0, 0, 1)),
    end: new Date(end_.setHours(23, 59, 59)),
  }
}


export function parseReferenceToString(obj: any) {
  /* need to parse out the doc references since it may cause circler json reference. JSON.parse complains about it */
  const parser = (a: any) => {
    return (a instanceof DocumentReference) ? a.path :
      (isObject(a) && !isArray(a) ? Object.keys(a).reduce((acc, k) => Object.assign(acc, { [k]: parser(a[k]) }), {}) :
        (isArray(a) ? (a.map(item => parser(item))) : a));

  };
  return parser(obj);

}

/**
 * sorts arrays and objects before JSON.stringify  
 * @param obj 
 */
export function getObjectHash(obj: any) {
  // console.time('getObjectHash')
  const parser = (a: any) => {
    return isObject(a) ? Object.keys(a).sort().reduce((acc, k) => Object.assign(acc, { [k]: parser(a[k]) }), {}) :
      (isArray(a) ? (a.sort().map(parser(a))) : a); // unreachable code detected :) 
  }
  const result = md5(JSON.stringify(parser(obj)))
  // console.timeEnd('getObjectHash')
  return result;
}


export const mkSimpleConnector = (path: string, docPath?: string, htmlElem?: HTMLInputElement): ConnectorPath<any> => {
  return {
    form_path: path,
    listenToState: false,
    upstream: ctl => ({ [docPath || path]: ctl.value }),
    htmlElement: htmlElem
  }
};


export const NO_MATCH = '$a';  // <-- this seems not to work 
export const simpleAutoCompleteFilter = (list: string[]) => pipe(startWith(), map((input: string) => input ? list.filter(item => item.toLowerCase().includes(input.toLowerCase())) : []));

export const noRegXReducer = (p, c) => `${p}|^${c}$`; // exact matches
export const regXReducer = (p, c) => `${p}|${c}`;



/**
 * Used in b-read form to extract the value which are true from the form to send it up
 *
 * @param obj
 */
export function filterTruthsValues(obj) {
  return Object.keys(obj).filter(v => obj[v] == true);
}

/**
 * reduces an array to an object with array items as keys valToApply ad the val for all the keys
 * @param arr
 * @param valToApply
 */
export function reduceToObject(arr: any[], valToApply = true) {
  return _castArray(arr).reduce((p, c) => (p[c] = valToApply, p), {});
}

/**
 * matches form controls in form groups to object by removing and adding controls.
 * I use this so I can use the original form (e.g no need to subscribe again)
 * @param formGroup
 * @param object
 */
export function rebuildFormGroup(formGroup: UntypedFormGroup, obj: { [key: string]: any }, blockEmit = false): UntypedFormGroup {
  Object.keys(obj).forEach(key => {
    const existingControl = formGroup.controls[key];
    const value = obj[key]?.value;
    const validators = obj[key].validators || [];
    if (existingControl) {
      existingControl.setValue(value, { emitEvent: !blockEmit });
      existingControl.setValidators(validators);
    } else { formGroup.addControl(key, new UntypedFormControl(value, validators)); }
  });
  const formKeys = Object.keys(formGroup.controls);
  _difference(formKeys, Object.keys(obj)).forEach(staleKey => formGroup.removeControl(staleKey));
  return formGroup;
}

export function unsubscribeMap(map_: { [key: string]: Subscription }) {
  Object.values(map_).map(sub_ => sub_ && sub_.unsubscribe());
}


/**
 * often we have a key in snake case. lookup in map for a display name if not remove underscore
 * @todo think about making this a pipe
 * @param key
 * @param map
 */
export function keyToDisplayName(key, map = {}) {
  return map[key] || _startCase(_toLower(key));
}


export function isApiUrl(url: string) {
  return url.startsWith(environment.serverURL + '/api');
}


/**
 *  given a form group it forces only one (or a group ,part of a group) to be on at the same time except keys except for keys given in exclude keys ot that they are in the same group.
 * @param fg
 * @param excludeKeys
 * @param groups
 */
export function toggleSingleSelectValueChanges(fg: UntypedFormGroup, excludeKeys: string[] = [], groups: any = {}): Observable<any> {

  let blockEmit = false;
  const controls_ = fg.controls;
  let fc: UntypedFormControl;

  const patched$ = Object.keys(controls_).reduce((acum, key) => {
    const fc = controls_[key];
    acum.push(fc.valueChanges.pipe(filter(_ => !blockEmit), map(v => {
      let patch: any;
      if (!v || excludeKeys.includes(key)) {
        patch = fg.value;
        patch[key] = fc.value;
        return patch;
      }
      let groupKeys = [];
      /*  groups have shape of name:[key1, key2,,,]  we are looking for the group to which the current key belongs to*/
      _forOwn(groups, (val_, ke_) => {
        if (val_.includes(key)) { groupKeys = val_; }
      });

      patch = Object.keys(fc.parent.controls).reduce((acum, curr) => {
        if (groupKeys.includes(curr) /* && key != curr */) {
          acum[curr] = fc.parent.controls[curr].value;

        } else {
          acum[curr] = key == curr;
        }
        return acum;
      }, {});

      return patch;
    })));
    return acum;
  }, []);

  return merge(...patched$).pipe(filter(_ => !blockEmit), map(patch_ => {
    blockEmit = true;
    fg.patchValue(patch_);
    blockEmit = false;
    return patch_;
  })).pipe(filter(_ => !blockEmit));
}

/**
 * provided a conversion callback function and a special false value ( a value that the callback returns if conversion fails)
 * it converts those items as described in callback
 *
 * @param value
 * @param callBack v: any, specialFalse: number, path:string[]
 * @param SPECIAL_FALSE_
 */
export function mapValuesDeep(value: any, mapperFunc: (v: any, specialFalse: number, path: string[]) => any, SPECIAL_FALSE_: number): any {

  const recursive_ = (value_: any, path_: string[]): any => {
    if (_isArray(value_)) { return value_.map(val_ => recursive_(val_, path_)); }
    if (_isPlainObject(value_)) {
      const newVal = {};
      Object.keys(value_).forEach((key_, index) => newVal[key_] = recursive_(value_[key_], [...path_, key_]));
      return newVal;
    }
    const return_val = mapperFunc(value_, SPECIAL_FALSE_, path_);
    return return_val !== SPECIAL_FALSE_ ? return_val : value_;
  };
  return recursive_(value, []);
}


/**
 * just like loDash isEqual but ignores order in first level arrays
 * @param obj1
 * @param obj2
 * @todo pass a sorter function but think about how that effects what it means to be plain array I think it does not matter since we only care for consistency;
 */
export function isSame(obj1, obj2, comparer = undefined) {
  const isPlainArray = (obj: any): boolean => _isArray(obj) && obj.every(v => !_isPlainObject(v) && !_isArray(v));
  const sorter = (obj: any): any => {
    if (isPlainArray(obj)) { return obj.sort(); }
    if (_isArray(obj) || _isPlainObject(obj)) { return _forEach(obj, (v, k) => sorter(v)); }
    return obj;
  };
  const sorted1 = sorter(obj1);
  const sorted2 = sorter(obj2);

  return comparer === undefined ? _isEqual(sorted1, sorted2) : _isEqualWith(sorted1, sorted2, comparer);
}

export function positionNextToTrigger(triggerElementRef: ElementRef, dialogRef): void {
  const matDialogConfig: MatDialogConfig = new MatDialogConfig();
  if (!(triggerElementRef && triggerElementRef.nativeElement)) { return; }
  const rect = triggerElementRef.nativeElement.getBoundingClientRect();
  matDialogConfig.position = { left: `${rect.left}px`, top: `${rect.bottom - 50}px` };
  dialogRef.updatePosition(matDialogConfig.position);
}


/**
 * given a date range in YYYY-MM-DD returns the range in YYYY-MM-DD HH:mm:ss.
 * used for between date filter to include the entire last day
 * @param start  yyyy-mm-dd
 * @param end
 */
export function dateRangeToDateTimeRange(start_, end_): { start: string, end: string } {
  return { start: start_, end: end_ ? moment(end_).add((24 * 60 * 60) - 1, 'seconds').format('YYYY-MM-DD HH:mm:ss').toString() : '' };
}

/**
 * consider empty string and empty array to be null
 * @param a if
 * @param b
 */
export function isNullable(a, b) {
  const _convert = _var => _isString(_var) || _isArray(_var) && _var.length == 0 ? null : _var;
  return _convert(a) === _convert(b);
}

/**
 * 
 * @param csv motivated my a google sheets range. 
 * @return a plain object like column.key(i.e column1) e.g last_name.some_id = braun
 */
export function csvArrayToRowMap(csv: string[][]) {

  const headers: string[] = csv[0];
  return csv.slice(1).map(row => {
    return row.reduce((p, v, i) => {
      p[headers[i]] = v
      return p;
    }, {})
  });
}


export function findInCsv(rowKey: string, column: string, csv: string[][], keyColumnIndex = 0) {
  const index = csv[0].findIndex(c => c.toLowerCase() == column.toLowerCase());
  const match = csv.find(row => row[keyColumnIndex].toLowerCase() == rowKey.toLowerCase());
  return match ? match[index] : ''
}

export function getCsvColumn(column: string, csv: string[][]) {
  const index = csv.shift().findIndex(c => c.toLowerCase() == column.toLowerCase());
  return csv.map(row => row[index]);

}

/**
 * 
 * @param csv column a contains keys, column b contain values: column d contains keys, column e contains values.  and so on.... 
 */
export function cvsArrayToSimpleColumnMap(csv: string[][]) {
  return csv.reduce((acc, v) => {
    v.forEach((v, i, arr) => i % 3 == 0 ? (acc[v] = arr[i + 1]) : null)
    return acc;
  }, {})
}


export function rowSavAs(rows: any[], fileName?: string, fileType?: 'csv' | 'json', headerMap?: { [key: string]: string }, extraText?: string, valueMap?: { [key: string]: string }): void {
  console.log(rows)
  const jsonToCsv = (rows: any[]): string => {
    const cellKeys = Object.keys(rows[0]).sort();
    let csv: string = cellKeys.map(headerCell => headerMap && headerMap[headerCell] ? headerMap[headerCell] : headerCell).join(',');
    return rows.reduce((p, c) => {
      p = p + ' ' + cellKeys.map(k => {
        if (valueMap && valueMap[c[k]]) return valueMap[c[k]];
        return isString(c[k]) ? c[k].replace(',', '-') : c[k]
      }).join(',');
      return p;
    }, csv);
  };
  const text = fileType == 'csv' ? jsonToCsv(rows) : JSON.stringify(rows);
  const fileName_ = (fileName || new Date().getTime().toString()) + '.' + fileType;
  const type_ = fileType == 'csv' ? "text/plain;charset=utf-8" : "application/json";
  return saveBlobAsFile(new Blob([text + (extraText || '')], { type: type_ }), fileName_);
}

export function fireStoreUpdateToAGird(api: GridApi,
  agGridRowID: string,
  // raw: DocumentChangeAction<any>[],
  // raw: Array<DocumentChange<AppModelType, DbModelType>>;
  raw: Array<DocumentChange>,
  emitIndex: number,
  converterCb?: (data: any, ref: DocumentReference) => any | boolean) {


  const newRows = [];
  const deleteRows = [];
  const updatedRows = [];
  for (let i = 0; i < raw.length; i++) {
    const DOC = raw[i];
    const rowData = converterCb ? converterCb(DOC.doc.data(), DOC.doc.ref) : DOC.doc.data();
    Object.assign(rowData, { [agGridRowID]: DOC.doc.ref.id })

    if (rowData === false) {
    } else {
      if (DOC.type == 'added') newRows.push(rowData);
      if (DOC.type == 'removed') deleteRows.push(rowData);
      if (DOC.type == 'modified') updatedRows.push(rowData);
    }
  }
  if (emitIndex < 1) {
    api.setGridOption('rowData', newRows)
  } else {
    newRows.length > 0 ? api.applyTransaction({ add: newRows }) : null;
    deleteRows.length > 0 ? api.applyTransaction({ remove: deleteRows }) : null;
    updatedRows.length > 0 ? api.applyTransaction({ update: updatedRows }) : null;
  }
  return true;
}

export function firestoreRefValuesChanges(docRef: DocumentReference): Observable<DocumentSnapshot> {
  return new Observable(obs => {
    const disposer = onSnapshot(docRef, snapshot => obs.next(snapshot), err => obs.error(err));

    return () => {
      disposer
    };
  })
}

export function firestoreCollectioRefValuesChanges(collectionRef: CollectionReference<any, any>): Observable<QuerySnapshot> {
  return new Observable(obs => {
    const disposer = onSnapshot(collectionRef, quereySnapshot => obs.next(quereySnapshot), err => obs.error(err));

    return () => {
      disposer
    };
  })
}

export function firestoreQueryValuesChanges(q: Query): Observable<QuerySnapshot> {
  return firestoreCollectioRefValuesChanges(q as CollectionReference)
}

export function firestorePathValuesChanges(path: string): Observable<DocumentSnapshot> {
  const ref_ = doc(getFirestore(), path);
  return firestoreRefValuesChanges(ref_);
}

export function authStatusPromiseFacotry() {
  return new Promise<User>((success, fail) => {
    onAuthStateChanged(getAuth(), user => success(user))
  });
}

export function saveBlobAsFile(blob: Blob, filename: string) {
  const blobUrl = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = blobUrl;
  link.download = filename;
  link.style.display = 'none';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(blobUrl);
}



export function displaySbackBarError(error: Error): void {
  const snackBar: MatSnackBar = window['MAT_SNACK_BAR'];
  snackBar.open(error.message, 'Close', {
    duration: 30000, // Duration the snackbar will be shown
    verticalPosition: 'top', // Vertical position
    panelClass: ['mat-toolbar', 'mat-warn'] // Styling classes
  });
}

let active_customer_id: string;
export function setActiveCustomerId(id: string): void {
  active_customer_id = id;
}
export function getActiveCustomerId(): string {
  if(!active_customer_id){
    throw new Error('active_customer_id not set')
  }
  return active_customer_id;
}

// http://www.myersdaily.org/joseph/javascript/md5-text.html
//JavaScript is a horrible language for writing this sort of program, but it is still useful for passwords, so I will try.

// The md5cycle() function will be called for every 64 bytes, and will transform them into a number suitable for addition to the md5 sum.

// An MD5 message, before the transformation, is converted into groups of 32-bit, positive numbers, 16 in each group. For the string "hello," this translation looks like this:

// hello = 68 65 6c 6c 6f
// 6c 6c 65 68,80 6f,0,0,0,0,0,0,0,0,0,0,0,0,28,0
// Apparently, the bytes of the word are taken four at a time, and written backwards into each segment, i.e., 68 65 6c 6c = 6c 6c 65 68, and 6f = 00 00 00 6f.

// Then the byte 80 is appended, as if it was part of the original string, i.e., 6f -> 6f 80 = 80 6f. (This is the effective result of appending a single bit, 1, in the form of a byte, but how it is I cannot see.)

// Finally, the length in bits (bytes times eight) is appended in two four-byte numbers, the last first, i.e., hello = 5 * 8 = 40 -> toBase16 -> 00 28 = 28 00.

// In C code it appears that four auxiliary functions are defined as such:

// typedef unsigned int word;
// word f(word x, word y, word z) { return x & y | ~x & z; }
// word g(word x, word y, word z) { return x & z | y & ~z; }
// word h(word x, word y, word z) { return x ^ y ^ z; }
// word i(word x, word y, word z) { return y ^ (x | ~z); }
// and

// f(00001111, 00110011, 01010101) = 01010011;
// g(00001111, 00110011, 01010101) = 00100111;
// h(00001111, 00110011, 01010101) = 01101001;
// i(00001111, 00110011, 01010101) = 10011100; // or -1100100 signed
// Four primary functions are defined for each of the 16 word places X[0..15] in the cycles of MD5. Also used here are 64 elements from a sine table, T[i = 0..63] = int(4294967296 * abs(sin(i+1))).

// word ff(word a, word b, word c, word d, word k, word s, word t) {
// return b + ((a + f(b, c, d) + k + T[t]) << s);
// }
// word gg(word a, word b, word c, word d, word k, word s, word t) {
// return b + ((a + g(b, c, d) + k + T[t]) << s);
// }
// word hh(word a, word b, word c, word d, word k, word s, word t) {
// return b + ((a + h(b, c, d) + k + T[t]) << s);
// }
// word ii(word a, word b, word c, word d, word k, word s, word t) {
// return b + ((a + i(b, c, d) + k + T[t]) << s);
// }
// /* Note that all are identical except the
//  * choice of auxiliary function internally.
//  * This would help JavaScript code.
//  * Probably the auxiliary can be brought
//  * inside of each function, or the function
//  * combined into the auxiliary.
//  */
// To perform the calculation, there are 64 steps.

// void md5cycle(word state[4], word X[16]) {
// word a=state[0], b=state[1], c=state[2], d=state[3];

// /* They call this round 1. */
// a = ff(a, b, c, d, X[0], 7, 1);
// d = ff(d, a, b, c, X[1], 12, 2);
// c = ff(c, d, a, b, X[2], 17, 3);
// b = ff(b, c, d, a, X[3], 22, 4);

// a = ff(a, b, c, d, X[4], 7, 5);
// d = ff(d, a, b, c, X[5], 12, 6);
// c = ff(c, d, a, b, X[6], 17, 7);
// b = ff(b, c, d, a, X[7], 22, 8);

// a = ff(a, b, c, d, X[8], 7, 9);
// d = ff(d, a, b, c, X[9], 12, 10);
// c = ff(c, d, a, b, X[10], 17, 11);
// b = ff(b, c, d, a, X[11], 22, 12);

// a = ff(a, b, c, d, X[12], 7, 13);
// d = ff(d, a, b, c, X[13], 12, 14);
// c = ff(c, d, a, b, X[14], 17, 15);
// b = ff(b, c, d, a, X[15], 22, 16);



// /* 2 */
// a = gg(a, b, c, d, X[1], 5, 17);
// d = gg(d, a, b, c, X[6], 9, 18);
// c = gg(c, d, a, b, X[11], 14, 19);
// b = gg(b, c, d, a, X[0], 20, 20);

// a = gg(a, b, c, d, X[5], 5, 21);
// d = gg(d, a, b, c, X[10], 9, 22);
// c = gg(c, d, a, b, X[15], 14, 23);
// b = gg(b, c, d, a, X[4], 20, 24);

// a = gg(a, b, c, d, X[9], 5, 25);
// d = gg(d, a, b, c, X[14], 9, 26);
// c = gg(c, d, a, b, X[3], 14, 27);
// b = gg(b, c, d, a, X[8], 20, 28);

// a = gg(a, b, c, d, X[13], 5, 29);
// d = gg(d, a, b, c, X[2], 9, 30);
// c = gg(c, d, a, b, X[7], 14, 31);
// b = gg(b, c, d, a, X[12], 20, 32);



// /* 3 */
// a = hh(a, b, c, d, X[5], 4, 33);
// d = hh(d, a, b, c, X[8], 11, 34);
// c = hh(c, d, a, b, X[11], 16, 35);
// b = hh(b, c, d, a, X[14], 23, 36);

// a = hh(a, b, c, d, X[1], 4, 37);
// d = hh(d, a, b, c, X[4], 11, 38);
// c = hh(c, d, a, b, X[7], 16, 39);
// b = hh(b, c, d, a, X[10], 23, 40);

// a = hh(a, b, c, d, X[13], 4, 41);
// d = hh(d, a, b, c, X[0], 11, 42);
// c = hh(c, d, a, b, X[3], 16, 43);
// b = hh(b, c, d, a, X[6], 23, 44);

// a = hh(a, b, c, d, X[9], 4, 45);
// d = hh(d, a, b, c, X[12], 11, 46);
// c = hh(c, d, a, b, X[15], 16, 47);
// b = hh(b, c, d, a, X[2], 23, 48);


// /* 4 */
// a = ii(a, b, c, d, X[0], 6, 49);
// d = ii(d, a, b, c, X[7], 10, 50);
// c = ii(c, d, a, b, X[14], 15, 51);
// b = ii(b, c, d, a, X[5], 21, 52);

// a = ii(a, b, c, d, X[12], 6, 53);
// d = ii(d, a, b, c, X[3], 10, 54);
// c = ii(c, d, a, b, X[10], 15, 55);
// b = ii(b, c, d, a, X[1], 21, 56);

// a = ii(a, b, c, d, X[8], 6, 57);
// d = ii(d, a, b, c, X[15], 10, 58);
// c = ii(c, d, a, b, X[6], 15, 59);
// b = ii(b, c, d, a, X[13], 21, 60);

// a = ii(a, b, c, d, X[4], 6, 61);
// d = ii(d, a, b, c, X[11], 10, 62);
// c = ii(c, d, a, b, X[2], 15, 63);
// b = ii(b, c, d, a, X[9], 21, 64);
// }
// To be faster, you can substitute the last expression in each step with the actual value from the sine table T (avoid the extra lookup each time).

// At the end of each cycle, you add the new values of a, b, c, d.

// state[0] += a;
// state[1] += b;
// state[2] += c;
// state[3] += d;
// Important: Note that all addition is unsigned, modulo 2^32.

// In the reference, MD5 is introduced with the process of padding the message. However, in actual practice this is performed at the end.

// The simplest way to incorporate the padding step is to allow the last < 16 "words" of the message to remain, and do

// t = l>>2;
// M[t] |= 0x80 << ((3 - (l%4))*8);
// where M are the remaining words of the message and l is the number of remaining bytes.

// Important: It is necessary to zeroize the bits of M past the end of the last actual byte (they may contain leftover random values). This step must actually be performed before the last step.

// M[t] &= ~(0xFFFFFF >> ((l%4) -1)*8);
// At this point you can reposition the data to the begining of M.

// /* assume that i - 16 points to the first
//  * unprocessed word of M.
//  */
// i -= 16;
// for (t=0; t<i%16; t++)
// M[t] = M[i+t];
// Proceed to zeroize up to 32 words.

// for (; t<32; t++)
// M[t] = 0;
// The last two words of M will hold a 64-bit number of the original bits in M. If the remaining length is < 56, we have enough room to fit them within the first 16 words. Otherwise, they will be placed as the 31st and 32nd.

// /* assume s is the 64-bit total of original bytes in M */
// s *= 8;
// if (l > 55) {
// M[30] = s & 0xFFFFFFFF;
// M[31] = s >> 32;
// /* cycle the two sets of 16 */
// md5cycle(state, M);
// md5cycle(state, &M[16]);
// } else {
// M[14] = s & 0xFFFFFFFF;
// M[15] = s >> 32;
// /* cycle the single set of 16 */
// md5cycle(state, M);
// }