<h5 mat-dialog-title> {{ message.message ? message.message : defaultTitle }} </h5>

<mat-dialog-content style="overflow: hidden">
    <ng-container *ngFor="let item of message.fields | keyvalue">
        <ng-container  *ngIf="item.value; else elseTemplate">
            <div class="alert alert-{{ cssType }}">{{ item.value }}</div>
        </ng-container>
        <ng-template #elseTemplate>
            <div class="alert alert-{{ cssType }}">{{ item.value | json }}</div>
        </ng-template>
    </ng-container>
    <div *ngIf="handleString">
        {{message}}
    </div>    
</mat-dialog-content>

<mat-dialog-actions >   
    <button (click)="prevMessage()" mat-icon-button [disabled]="!errors.prev"  ><mat-icon>chevron_left</mat-icon></button>
    <button (click)="nextMessage()" mat-icon-button [disabled]="!errors.next" ><mat-icon>chevron_right</mat-icon></button>
    <button mat-button (click)="reloadApp()" tabindex="1">Reload App</button>
    <button mat-button (click)="reloadApp(true)" tabindex="1">Reload App Home</button>
</mat-dialog-actions>


