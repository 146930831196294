import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
    selector: 'app-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
    host: {
        "[style.pointer-events]": "disabled ? 'none' : 'auto'"
      },
    standalone:true,
    imports:[CommonModule, MatTooltipModule]
})
export class ButtonComponent implements OnInit, OnChanges {
    // The label to be displayed in a button. Leave this empty if you want an icon only button.
    @Input() label: string;

    // A specific class for a button
    @Input() class: string = 'button-blue';

    // Determines whether a button has dropdown or not
    @Input() hasDropdown: boolean = false;

    // Determines whether the button is disabled
    @Input() disabled: boolean = false;

    // Set this to true if you want an icon only button
    @Input() iconOnly: boolean = false;

    // Set this to true if you want an icon above and text below type of button
    @Input() iconOnTop: boolean = false;

    // The CSS class of the icon
    @Input() icon: string = '';

    // The tooltip
    @Input() tooltip: string;

    public showDropdown: boolean;
    public isBlock: boolean;

    constructor() {
        this.showDropdown = false;
        this.isBlock = false;
    }

    ngOnInit() {
    }

    ngOnChanges() {
        if (this.class.indexOf('button-block') > -1) {
            this.isBlock = true;
        }
    }

    dropdownTriggerClick(event) {
        event.stopPropagation();
        this.showDropdown = !this.showDropdown;
    }
}