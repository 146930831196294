import { Injectable } from '@angular/core';
import { get as _get } from 'lodash-es';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
    private userDataRepo = {};
    private username: string;
    private service_was_int :boolean = false;

    constructor(private authService: AuthService){
        
     
    }

    public getUserData(keys:string[]){
        this.init_()
        return _get(this.userDataRepo[this.username],keys);
    }

    public updateUserData(key:string, value:any){
        this.init_()
        this.userDataRepo[this.username][key] = value;
        this.pushNewData();
    }

    private initFromLocalStorage(){
        let storageData;
        let cached_storageData;
        if(cached_storageData = window.localStorage.getItem("user_data")){
          storageData = JSON.parse(cached_storageData);  
        }
        else {
            storageData = {}; 
        }
        if(!storageData[this.username])storageData[this.username] = {};
        
        return  storageData;
    }

    private init_ (){
        if(this.service_was_int)return true;
        this.username = this.authService.currentUserAuthDataBehavior.getValue().username;
        /* to be overridden , partially or completely, by api call for server data */
        this.userDataRepo = this.initFromLocalStorage();  
        this.service_was_int = true;
        return this.service_was_int;
    }

    private pushNewData(){
        window.localStorage.setItem("user_data",JSON.stringify(this.userDataRepo))
    }
}