export const environment = {
  production: false,
  serverURL: 'https://api.bvrad.com',
  cloudFunctionsUrl: 'https://us-central1-fir-demo-ede56.cloudfunctions.net',
  pacsBaseUrl: "https://healthcare.googleapis.com",


  marketingContactSheet: '1itEUr1QNaCDttqFt_jZZGoL9LTg22YEXGEPJoi-11G4',

  dcm_session_path: 'dcm_worklist',

  vendorTypes: [
    "radiologist",
    "transcriptionist"
  ],

  firebaseConfig: {
    apiKey: "AIzaSyAXUwmEfTOIy_vKVE6NX5HPPpk3OHo_eoE",
    authDomain: "heroic-goal-278716.firebaseapp.com",
    databaseURL: "https://heroic-goal-278716.firebaseio.com",
    projectId: "heroic-goal-278716",
    storageBucket: "heroic-goal-278716.appspot.com",
    messagingSenderId: "903609461684",
    appId: "1:903609461684:web:1a02f136ea142e148060e1"

  },

  radiologyReportsStoragePath:'radiology-reports',


  /* viewer stuff */
  importUploader: true,
  importViewer: true,
  viewerPath: 'staticHtml/viewerIframe.html',
  fireStorageIframe: true,
  // iframeOrigin: 'https://bvr-dcm.web.app',
  dcm_preview_img_bucket: 'dcm_preview_images',
  dcm_related_studies_db: 'dcm_related_studies',

  /* canned text */
  canned_txt_path: "canned-text",
  ignore_cdm_cache: false,
  claim_property: {},


  /* docs repo */
  report_repo_path: 'report_repository',
  report_repo_list_path: 'report_repository_lists',
  report_app_path: 'apps/portal/report',
  report_app_path_web_list_path: 'apps/portal/list',

  /* Uploader section */
  uploader_failed_firestore_path: 'uploader_failed',


  /* app access tokens */
  app_access: ['transcribeAccess', 'admin'],
  transcribe_access: ['transcribeStaff', 'admin'],
  admin: ['admin'],
  customer_access:['customer_access','transcribeAccess'],


  firestore_paths: {
    worklists:'worklist', // all types replaces dcm worklists 
    bvr_studies: 'studies',
    customers: 'customers',
    customer_files_collection_id :'customer_files',
    reading_tickets: 'read_tkts',
    config: 'config',
    study_types: 'config/hkn3Svz3lakI1i69lAMV',
    misc_config: 'config/8104WTgxDjPhVGH0L6nO',
    invoices: 'invoices',
    invoice_shards:'invoice_shards',
    radiology_reports: 'radiology_reports',
    radiologists: 'radiologists',
    radiologyReportTemplates: 'rprt_tmplts',
    radiologyReportContent: 'rad_report_content', 
    dcm_studies: {
      root:'dcm_studies',
      processed:'processed',
      failed:'failed',
      orthanc_backup: 'dcm_studies/jJKnF71VMVR7wqkH5i20',
    },


  },

  fireStorage_paths: {
    rad_signatures: 'radiologists_items/signatures',
  },

  useOrhancAdaptor: true,
  anonymize_phi:false,
  

};


