import { Injectable, Inject, OnDestroy, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class ScrollService {

  @Output() scrollChange: EventEmitter<void> = new EventEmitter<void>();

  constructor( ) {}

  public emitChangeEvent(): void {
    this.scrollChange.emit();
  }
  
  public getScrollChangeEmitter(): EventEmitter<void> {
    return;
  }
}
