import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { DropdownOption } from "../dropdown-button/dropdown-button.component";
import { CommonModule } from "@angular/common";
import { MatTooltipModule } from "@angular/material/tooltip";

@Component({
    selector: 'app-multiselect',
    templateUrl: './multiselect.component.html',
    styleUrls: ['./multiselect.component.scss'],
    standalone:true,
    imports:[CommonModule,MatTooltipModule]
})
export class MultiselectComponent implements OnInit {
    @Input() icon: string = 'ri-equalizer-line';
    @Input() label: string = 'All';

    @Input() options: DropdownOption[] = [];
    @Input() tooltip: string;
    @Output() onChange: EventEmitter<DropdownOption[]> = new EventEmitter();

    public showOptions: boolean = false;


    constructor() { }

    ngOnInit() {
        // console.log(this.options)
     }

    public toggleOption(opt: DropdownOption) {
        opt.selected = !opt.selected;
       
        this.onChange.emit(this.options);
    }

    public isOptionSelected(opt: DropdownOption) {
        return opt.selected;
    }

    public get toogleAllStatus() {
        return this.options.every(x =>x.selected) ? 'ALL' : 
            (this.options.some(x =>x.selected) ?  'SOME' :'NONE' ); 
    }

    public toggleAll() {
        
        this.options.every(x =>x.selected)? this.options.forEach(a => a.selected = false) :
        this.options.forEach(a => a.selected = true)

        this.onChange.emit(this.options);
    }

    public get selectedCount(){
        return this.options?.filter(x => x.selected).length || '';
    }
}

