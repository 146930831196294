import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { isEmpty as _isEmpty } from 'lodash-es';
import { Subscription } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { SearchOption, TableService } from '../../services';
import { MatChipsModule } from '@angular/material/chips';
import { MatRadioModule } from '@angular/material/radio';
import { MatIconModule } from '@angular/material/icon';



export interface SearchOptionConfig {
    tableService: TableService;
    hasOptions: boolean;
    options: Array<SearchOption>;
}



@Component({
    selector: 'search-with-options',
    templateUrl: 'search-with-options.component.html',
    styleUrls: ['search-with-options.component.scss'],
    standalone: true,
    imports: [MatChipsModule ,MatRadioModule,MatIconModule, ReactiveFormsModule]
})
export class SearchWithOptionsComponent implements OnInit {
    public isDropdownVisible: boolean;
    public searchTerm: string;
    public searchOption: SearchOption;
    public searchInput = new UntypedFormControl();
    public tableService: TableService;
    public inputChangeSub: Subscription;

    @Input() config: SearchOptionConfig;
    @Output() search: EventEmitter<any> = new EventEmitter<any>();


    constructor() {
        this.isDropdownVisible = false;
    }

    ngOnInit() {
        this.tableService = this.config.tableService;
        if (!this.tableService) throw 'must pass a table service';
        this.searchOption = this.config.options.find(item => item.default);
        this.inputChangeSub = this.searchInput.valueChanges.pipe(
            debounceTime(500),
            tap(() => this.isDropdownVisible = false))
            .subscribe(v => (this.searchOption.val = v, this.tableService.applySimpleFilters([this.searchOption])));
    }
    ngOnDestroy(): void {
        this.inputChangeSub.unsubscribe();
    }

    public toggleDropdown() {
        this.isDropdownVisible = !this.isDropdownVisible;
    }

    public changeDropdownOption(opt: SearchOption): void {
        this.searchOption = this.config.options.find(item => item.key == opt.key)
        this.searchInput.setValue('', { emitEvent: !_isEmpty(this.searchInput.value) });
        this.isDropdownVisible = false;
    }

    public hideDropdown() {
        if (this.isDropdownVisible) {
            this.isDropdownVisible = false;
        }
    }
}