import { Injectable, NgZone } from '@angular/core';
import { Observable, from, of, isObservable, concat, merge } from 'rxjs';
import { map, tap, skip, filter } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NotificationService } from './notification.service'
import { HttpClient } from '@angular/common/http'
import {difference as _difference} from 'lodash-es';




const CACHE_KEY = 'CACHE_KEY_SHVVA_MIZVZS_BNi_NOIACH'
const CACHE_SESSION_KEY = 'HAREG_VAL_YAVOR';

@Injectable({providedIn:'root'})
export class CacheService {


    constructor(private notificationService: NotificationService, private httpClient: HttpClient) {
        
        merge(this.notificationService.messageBusSource$.pipe(filter(message =>message == 'CLEAR_CACHE'))).subscribe(_ => this.reloadCache());
        this.localCache = JSON.parse(localStorage.getItem(CACHE_KEY)) || {};
    }

    private localCache: any = {};
    private retrievers: { [key: string]: Observable<any> } = {};
    
    public get(key: string, obs$: Observable<any>): Observable<any> {
        // return obs$; // off switch
        this.retrievers[key] = obs$; // always pass since we don't  (I can't) store an observable easily in local storage.  
        const exists = this.localCache[key] || false;
        if (exists) return of(exists);
        return this.retrieve(key, obs$);
    }

    public getSnapshot(key:string){
        return this.localCache[key];
    }

    private reloadCache() {
        console.log('reloading cache')
        localStorage.removeItem(CACHE_KEY);
        let batch$: Observable<any>[] =[];
        for (let [k, v] of Object.entries(this.retrievers)) {
            batch$.push(this.retrieve(k,v));
        }
        concat(...batch$).subscribe(_=>_); // just do one at a time.
    }
    private retrieve(key:string,obs$: Observable<any>): Observable<any>{
        return obs$.pipe(tap(v => {
            this.localCache[key] = v
            this.flush();
        }));
    }
    private flush() {
        localStorage.setItem(CACHE_KEY, JSON.stringify(this.localCache));
    }


    /*  session state api */
    public getSessionVar(key:string){
        const s_cache = JSON.parse(localStorage.getItem(CACHE_SESSION_KEY)) || {};
        return s_cache[key];
    }
    public setSessionVar(key:string, val:any){
        const s_cache =  JSON.parse(localStorage.getItem(CACHE_SESSION_KEY)) || {};
        s_cache[key] = val;
        localStorage.setItem(CACHE_SESSION_KEY, JSON.stringify(s_cache));
        return true;
    }
}