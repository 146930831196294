<div  class="app-button-container" [ngClass]="{'full-height': iconOnly, 'full-width': isBlock}" (clickOutside)="showDropdown = false">
    <button [matTooltip]="tooltip" class="button {{class}}" [ngClass]="{'with-dropdown': hasDropdown, 'button-disabled': disabled, 'with-icon': iconOnTop}">
        <span *ngIf="iconOnly" class="icon-only-button {{icon}}"></span>
        <span *ngIf="iconOnTop" class="inside-icon {{icon}}"></span>
        <span *ngIf="label">{{label}}</span>
        <span *ngIf="hasDropdown" class="dropdown-icon" (click)="dropdownTriggerClick($event)">
            <i *ngIf="!iconOnly" [ngClass]="{'ri-arrow-down-s-line': !showDropdown, 'ri-arrow-up-s-line': showDropdown}"></i>
            <i *ngIf="iconOnly" [ngClass]="{'ri-arrow-drop-down-fill': !showDropdown, 'ri-arrow-drop-up-fill': showDropdown}"></i>
        </span>
    </button>
    <div *ngIf="showDropdown" class="dropdown-menu" (click)="showDropdown = false">
        <ng-content></ng-content>
    </div>
</div>