import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { first } from 'rxjs/operators';
import { FilesService, NoteService } from '../../services';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-report-modal',
  templateUrl: './report-modal.component.html',
  styleUrls: ['./report-modal.component.scss'],
  standalone: true,
  imports: [MatDialogModule, MatIconModule]

})
export class ReportModalComponent implements OnInit, OnDestroy {
  public pdfUrl: string;
  public textAreaData: string;
  private isNewComment: boolean;
  private noteId: number;
  public studyServiceId;
  @Input('pdfUrl')
  private passedPdfUrl: string;


  constructor(public dialogRef: MatDialogRef<any>,
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private noteService: NoteService,
    private filesService: FilesService) { }

  ngOnDestroy() {
    window.URL.revokeObjectURL(this.pdfUrl);
  }
  public ngOnInit() {
    this.pdfUrl = this.data?.element_data || this.passedPdfUrl;
    if (this.pdfUrl) this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.pdfUrl) as string;
  }

  public closeModal() {
    this.dialogRef.close();
  }

  public onSubmit() {
    if (this.isNewComment) {
      this.noteService.postNote(this.data.element_data.id, this.textAreaData)
        .subscribe(
          (response: any) => { },
          error => {
            console.log(error);
          }
        );
    }
    if (!this.isNewComment) {
      this.noteService.putNote(this.noteId, this.textAreaData)
        .subscribe(
          (response: any) => {
          },
          error => {
            console.log(error);
          }
        );
    }

    this.dialogRef.close();
  }

  public deleteNote() {
    this.noteService.deleteNote(this.noteId)
      .pipe(first())
      .subscribe(
        (response: any) => {
          this.textAreaData = '';
        },
        error => {
          console.log(error);
        }
      );

    this.dialogRef.close();
  }
}
